import React, { Component } from 'react';

export default class SiteBlocks extends Component {
  constructor(props) {
    super(props);
    this.blockComponents = [];
    this.requireComponents(this.props.blocks);
  }

  requireComponents(blocks) {
    blocks.forEach((block) => {
      var blockName = block.__typename.substr(10);
      var blockPathName = blockName.replace(/([a-z])([A-Z])/g, '$1-$2').toLowerCase();
      if (!this.blockComponents[blockName]) {
        try {
          this.blockComponents[blockName] = require(`components/blocks/${blockPathName}/${blockPathName}.js`).default;
        } catch (e) {
          //console.log(`======> PLEASE ADD MISSING COMPONENT: ${blockName}`);
        }
      }
    });
  }

  getAdditionalPropsForBlock(block) {
    if (
      !block ||
      !(this.props.additionalPropsForBlocks instanceof Array && this.props.additionalPropsForBlocks.length)
    ) {
      return null;
    }
    const blockPropMap = this.props.additionalPropsForBlocks.find(
      (blockPropMap) => blockPropMap.typename === block.__typename
    );
    return blockPropMap ? blockPropMap.props : null;
  }

  render() {
    return (
      <div className={'blocks'}>
        {this.props.blocks.map((node, i) => {
          const Block = this.blockComponents[node.__typename.substr(10)];
          const additionalProps = this.getAdditionalPropsForBlock(node);
          return Block ? <Block key={node.id + i} {...node} {...additionalProps} /> : '';
        })}
      </div>
    );
  }
}
