class rssHelper {
  feedIcon(something) {
    switch (something) {
      case 'Fotbollskanalen':
        return {
          src: '/images/newsfeed-icons/fotbollskanalen.svg',
          alt: 'Fotbollskanalen',
        };
      case 'Fotbolltransfers':
        return {
          src: '/images/newsfeed-icons/fotbolltransfers.svg',
          alt: 'Fotbolltransfers',
        };
      case 'FotbollDirekt':
        return {
          src: '/images/newsfeed-icons/fotbolldirekt.svg',
          alt: 'Fotbolldirekt',
        };
      case 'Hockeysverige':
        return {
          src: '/images/newsfeed-icons/hockeysverige.jpg',
          alt: 'Hockey Sverige',
        };
      default:
    }
  }
}

const helper = new rssHelper();
export default helper;
