import React, { Component } from 'react';
import PropTypes from 'prop-types';
import UiBkgImageEventAttributeStore from './helpers/ui-bkg-image-event-attribute-store'
import UiBkgImageEventAggregator from './helpers/ui-bkg-image-event-aggregator'
import ScrollParent from './helpers/ui-bkg-image-scroll-parent'
import ImageTypeSupportHelper from './helpers/image-type-support-helper.js';
import './ui-bkg-image.less';

export default class UiBkgImage extends Component {

    static defaultProps = {
        className: "",
        breakPointSmallDevice: 1023,
        largeDeviceImageMaxWidth: 2880,
        smallDeviceImageMaxWidth: 1536,
        imageUrlForSmallDevice: "",
        imageUrlForLargeDevice: "",
        imageQuality: 70,
        isUsingPreviewResize: true,
        enableFadeIn: false
    }

    constructor(props) {
        super(props);
        this.isLargeDeviceImageLoading = false;
        this.isSmallDeviceImageLoading = false;
        this.onClick = this.onClick.bind(this);
        this.onAnimationEndSmallDevice = this.onAnimationEndSmallDevice.bind(this);
        this.onAnimationEndLargeDevice = this.onAnimationEndLargeDevice.bind(this);
        this.ref_element = React.createRef();

        this.state = {
            isSmallDevice: null,
            isImageForSmallDeviceLoaded: null,
            isImageForLargeDeviceLoaded: null,
            isImageInScreen: null,
            isWebPSupported: null,
            isAnimationForSmallDeviceCompleted: false,
            isAnimationForLargeDeviceCompleted: false
        }
    }

    componentDidMount() {
        this._isMounted = true;
        this.windowWidth = UiBkgImageEventAttributeStore.getCurrentWindowWidth();

        if (window.IntersectionObserver) {
            this.initializeIntersectionObserver();
        } else {
            this.initializeOnScroll();
        }

        this.onResizeWindowWidthSubscription = UiBkgImageEventAggregator.subscribe("onResizeWindowWidth", (windowWidth) => this.windowWidthChanged(windowWidth));

        const imageInScreen = this.isInScreen();
        this.setState({ isSmallDevice: this.windowWidth < this.props.breakPointSmallDevice, isImageInScreen: imageInScreen });

        ImageTypeSupportHelper.getWebPResult().then(response => {
            if (this._isMounted) {
                this.setState({ isWebPSupported: response })
            }
        });
    }

    componentWillUnmount() {
        this._isMounted = false;

        if (this.onResizeWindowWidthSubscription) {
            this.onResizeWindowWidthSubscription.remove();
        }

        if (this.onScrollSubscription) {
            this.onScrollSubscription.remove();
        }

        if(this.observer) {
            this.observer.disconnect();
        }
    }

    componentDidUpdate() {
        this.isImageInScreenOnRender();
    }

    initializeIntersectionObserver() {
        this.observer = new IntersectionObserver(
            entries => {
                entries.forEach(entry => {
                    const { isIntersecting } = entry;
                    if (isIntersecting) {
                        this.setState({ isImageInScreen: true });
                        this.observer = this.observer.disconnect();
                    }
                });
            },
            { rootMargin: "0px 0px 100% 0px" });
            this.observer.observe(this.ref_element.current);
    }

    initializeOnScroll() {
        // IE
        const scrollableParent = ScrollParent(this.ref_element.current);
        this.hasScrollableParent = scrollableParent ? true : false;

        UiBkgImageEventAggregator.initListeners(scrollableParent);
        this.onScrollSubscription = UiBkgImageEventAggregator.subscribe(this.hasScrollableParent ? "overflowOnScroll" : "onScroll", () => this.onScroll());
    }

    onScroll() {
        if (this.isInScreen()) {
            this.setState({ isImageInScreen: true });

            if (this.onScrollSubscription) {
                this.onScrollSubscription.remove();
            }
        }
    }

    isInScreen() {
        if (typeof window === 'undefined') {
            return false;
        }

        if (!(this.ref_element && this.ref_element.current)) {
            return false;
        }

        let rect = this.ref_element.current.getBoundingClientRect();
        const windowHeight = UiBkgImageEventAttributeStore.getCurrentWindowHeight();
        return rect.bottom > 0 && rect.top <= (windowHeight * 2); // "Increase" window height to make sure images start to load before they are in viewport
    }

    windowWidthChanged(windowWidth) {
        let isSmallDevice = windowWidth < this.props.breakPointSmallDevice;
        this.windowWidth = windowWidth;

        if (isSmallDevice !== this.state.isSmallDevice) {
            this.setState({ isSmallDevice: isSmallDevice });
        }
    }

    getImageUrl() {
        return this.state.isSmallDevice ? this.props.imageUrlForSmallDevice : this.props.imageUrlForLargeDevice
    }

    getImageRestrictWidthTo() {
        let devicePixelRatio = window.devicePixelRatio && window.devicePixelRatio > 1 ? Math.min(window.devicePixelRatio, 2) : 1;

        return this.state.isSmallDevice ? Math.min(devicePixelRatio * this.windowWidth, this.props.smallDeviceImageMaxWidth) :
            Math.min(devicePixelRatio * this.windowWidth, this.props.largeDeviceImageMaxWidth);
    }

    loadLargeDeviceImage(url) {
        if (!this.isLargeDeviceImageLoading) {
            this.isLargeDeviceImageLoading = true;
            let image = new Image();
            image.onload = () => { if (this._isMounted) { this.setState({ isImageForLargeDeviceLoaded: true }); } }
            image.onerror = () => { if (this._isMounted) { this.setState({ isImageForLargeDeviceLoaded: false }); } }
            image.src = url;
        }
    }

    loadSmallDeviceImage(url) {
        if (!this.isSmallDeviceImageLoading) {
            this.isSmallDeviceImageLoading = true;
            let image = new Image();
            image.onload = () => { if (this._isMounted) { this.setState({ isImageForSmallDeviceLoaded: true }); } }
            image.onerror = () => { if (this._isMounted) { this.setState({ isImageForSmallDeviceLoaded: false }); } }
            image.src = url;
        }
    }

    isImageLoaded() {
        return this.state.isImageForSmallDeviceLoaded || this.state.isImageForLargeDeviceLoaded;
    }

    onClick(event) {
        if (this.props.onClick) {
            this.props.onClick(event);
        }

        return true;
    }

    onAnimationEndLargeDevice() {
        this.setState({ isAnimationForLargeDeviceCompleted: true });
    }

    onAnimationEndSmallDevice() {
        this.setState({ isAnimationForSmallDeviceCompleted: true });
    }

    isImageComponentReadyToRender() {
        return typeof window !== 'undefined' &&
            this.state.isWebPSupported !== null &&
            this.state.isSmallDevice !== null &&
            this.state.isImageInScreen !== null &&
            this.state.isImageInScreen;
    }

    isImageInScreenOnRender() {
        if (!this.state.isImageInScreen && this.isInScreen()) {
            this.setState({ isImageInScreen: true });
        }
    }

    render() {
        if (!this.isImageComponentReadyToRender()) {
            return <>
                {/* eslint-disable-next-line */}
                <div onClick={this.onClick} ref={this.ref_element} className={['ui-bkg-image__wrapper', this.props.className].join(" ")}></div>
            </>;
        }

        let queryWebp = "";
        let imageQuality = `&q=${this.props.imageQuality}`;
        let url = "";
        let previewResizeWidth = false;

        if (this.windowWidth) {
            url = this.getImageUrl();
            queryWebp = this.state.isWebPSupported && url.indexOf('.svg') < 0 ? "&fm=webp" : "";

            this.restrictWidthTo = parseInt(this.getImageRestrictWidthTo());
            previewResizeWidth = parseInt(this.restrictWidthTo / 40);

            if (this.state.isImageInScreen && url) {
                this.state.isSmallDevice ?
                    this.loadSmallDeviceImage(`${url}?w=${this.restrictWidthTo}${queryWebp}${imageQuality}`) :
                    this.loadLargeDeviceImage(`${url}?w=${this.restrictWidthTo}${queryWebp}${imageQuality}`);
            }
        }

        const isImageLoaded = this.isImageLoaded();
        const previewDisplayClass = isImageLoaded ? this.props.enableFadeIn ? 'fade-out' : 'hidden' : "";
        const imageDisplayClass = isImageLoaded ? this.props.enableFadeIn ? 'fade-in' : "" : "";

        return (
            /* eslint-disable-next-line */
            <div onClick={this.onClick} ref={this.ref_element} className={['ui-bkg-image__wrapper', this.props.className].join(" ")}>
                {
                    !url &&
                    <div className={'ui-bkg-image__image-preview'}></div>
                }
                {
                    url && this.props.isUsingPreviewResize && this.state.isImageInScreen && previewResizeWidth &&
                    <div className={['ui-bkg-image__image-preview', previewDisplayClass].join(' ')} style={{ 'backgroundImage': `url(${url}?w=${previewResizeWidth})`}} ></div>
                }
                {
                    url && this.state.isImageInScreen && this.state.isSmallDevice === true && this.state.isImageForSmallDeviceLoaded &&
                    <div onAnimationEnd={this.onAnimationEndSmallDevice} className={['ui-bkg-image__image', imageDisplayClass].join(" ")} style={{ 'backgroundImage': `url(${url}?w=${this.restrictWidthTo}${queryWebp}${imageQuality})`}} ></div>
                }
                {
                    url && this.state.isImageInScreen && this.state.isSmallDevice === false && this.state.isImageForLargeDeviceLoaded &&
                    <div onAnimationEnd={this.onAnimationEndLargeDevice} className={['ui-bkg-image__image', imageDisplayClass].join(" ")} style={{ 'backgroundImage': `url(${url}?w=${this.restrictWidthTo}${queryWebp}${imageQuality})`}} ></div>
                }
                {
                    this.props.children
                }
            </div>)
    }
}

UiBkgImage.propTypes = {
    className: PropTypes.string,
    breakPointSmallDevice: PropTypes.number,
    largeDeviceImageMaxWidth: PropTypes.number,
    smallDeviceImageMaxWidth: PropTypes.number,
    imageUrlForSmallDevice: PropTypes.string,
    imageUrlForLargeDevice: PropTypes.string,
    imageQuality: PropTypes.number,
    isUsingPreviewResize: PropTypes.bool,
    enableFadeIn: PropTypes.bool
}
