import React, { Component } from 'react';
import get from 'lodash/get';
import styles from './widget-block.module.less';
import { graphql } from 'gatsby';

export default class WidgetBlock extends Component{
    
    constructor(props){
        super(props);
        this.title = get(props, 'title');
        this.text = get(props, 'text.childMarkdownRemark.html'); 
        this.image = get(props, 'image.file.url'); 
        this.addRedBoxToTitle = get(props, 'addRedBoxToTitle');
    }

    render(){
        const firstTitleWord = this.title && this.title.split(' ')[0];
        const restTitle = this.title && this.title.split(' ')[1];
        return(
            <>
                {
                    this.title && this.text &&
                    <section className={[styles.widgetBlock].join(" ")}>
                        <div className={[styles.widgetBlock__textWrapper].join(" ")}>
                            {this.title && !this.addRedBoxToTitle && 
                                <div className={styles.widgetBlock__title}>{this.title}</div>
                            }
                            {this.title && this.addRedBoxToTitle && 
                                <div className={styles.widgetBlock__title}>
                                    <span className={styles.widgetBlock__firstTitleWord} >{firstTitleWord}</span>
                                    <span>{restTitle}</span>
                                </div>
                            }
                            <div className={[styles.widgetBlock__text].join(" ")} dangerouslySetInnerHTML={{__html: this.text}}></div>
                            {this.image && <img className={styles.widgetBlock__image} src={this.image} alt="widgetImage"></img>}
                        </div>
                    </section>
                }
            </>
        )
    }
}

export const fragmentWidgetBlock = graphql`
    fragment WidgetBlock on ContentfulWidgetBlock{
        ... on ContentfulWidgetBlock{
            id
            title
            text{
                childMarkdownRemark{
                    html
                }
            }
            image{
                file{
                    url
                }
            }
            addRedBoxToTitle
        }
    }
`