class UiBkgImageEventAttributeStore {

    constructor() {
        this.windowWidth = null;
        this.windowHeight = null;
        this.scrollY = null;
        this.overflowScrollY = null;
        this.isPopState = null;
    }

    getCurrentWindowWidth(){
        if(typeof window === 'undefined'){
            return null;
        }

        return this.windowWidth === null ? 
              (window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth) : this.windowWidth;
    }

    getCurrentWindowHeight(){
        return this.windowHeight === null ? 
              (window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight) : this.windowHeight;
    }

    getCurrentScrollY(){
        if(typeof window === 'undefined'){
            return null;
        }
        
        return this.scrollY === null ? 
              (window.scrollY || window.pageYOffset || document.documentElement.scrollTop) : this.scrollY;
    }

    getOverflowScrollY() {
        if(typeof window === 'undefined'){
            return null;
        }

        return this.overflowScrollY;
    }
}

const singleton = new UiBkgImageEventAttributeStore();
export default singleton;