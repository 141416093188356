import React, { Component } from 'react'
import get from 'lodash/get'
import styles from './card-list-block.module.less'
import { graphql } from 'gatsby'
import CardBlock from '../card-block/card-block'

export default class CardListBlock extends Component {
  constructor(props) {
    super(props)
    this.cardList = get(props, 'cardList')
  }

  render() {
    return (
      <section className={styles.cardListBlock}>
        <div className={styles.cardListBlock__container}>
          {this.cardList instanceof Array &&
            this.cardList.length &&
            this.cardList.map(card => {
              return <CardBlock {...card} key={card.id} />
            })}
        </div>
      </section>
    )
  }
}

export const fragmentCardListBlock = graphql`
  fragment CardListBlock on ContentfulCardListBlock {
    id
    cardList {
      ...CardBlock
    }
  }
`
