import React, { Component } from 'react';
import get from 'lodash/get';
import { graphql } from 'gatsby';
import SelectDropdown from 'components/block-elements/select-dropdown/select-dropdown';
import SessionStorageHelper from 'components/helpers/session-storage-helper'
import UiBkgImage from 'components/block-elements/ui-bkg-image/ui-bkg-image';
import MatchListOdds from 'components/block-elements/match-list-odds/match-list-odds'
import styles from './odds-comparsion-block.module.less';

export default class OddsComparsionBlock extends Component {
  constructor(props) {
    super(props);

    this.tournaments = get(props, 'sport.tournaments');
    this.tournamentImageUrl = get(props, 'sport.image.file.url',"")
    this.selectOptions = this.formatToSelectOptions(this.tournaments.filter(tournament => tournament.inActivate !== true));
    this.sportId = get(props, 'sport.sportId');
    this.sessionCachKey = "odds_comparsion_tournament_selection" + this.sportId;
    
    this.state = {
      selectLeague: this.getSelectedLeagueFromCache() ? this.getSelectedLeagueFromCache() :
                                                        this.selectOptions instanceof Array && this.selectOptions.length > 0 ? this.selectOptions[0] : null
    }
  }

  getSelectedLeagueFromCache(){
    if(typeof window === 'undefined'){
      return null;
    }

    return SessionStorageHelper.getItem(this.sessionCachKey) ? SessionStorageHelper.getItem(this.sessionCachKey) : null
  }

  getCacheExpirationTimeStamp(){
    let now = new Date();
    return now.setMinutes(now.getMinutes() + 60)
  }

  sessionUpdate(){
    SessionStorageHelper.setItem(this.sessionCachKey,this.state.selectLeague)
  }

  getSelectedBtnHtml(props){
    return this.state.selectLeague instanceof Object ?
    <><span className={styles.label}>{props.label}</span><span className={styles.selectedOuter}><span className={styles.selectedInner}></span></span></> : ""
  }

  formatToSelectOptions(tournaments) {
    return (
      tournaments &&
      tournaments.map(item => ({
        key: item.tournamentId,
        value: item.tournamentId,
        text: item.tournamentName,
        label: item.tournamentName
      }))
    );
  }

  render() {
    return (
      <section className={styles.tournamentStandingsWrapper}>
        <div className={styles.heroOuterWrapper}>
          <div className={['block__max-width', styles.heroInnerWrapper].join(' ')}>
            {
              this.tournamentImageUrl ?
              <UiBkgImage
                className={[styles.image__imageWrapper].join(" ")}
                breakPointSmallDevice={1023}
                imageUrlForSmallDevice={this.tournamentImageUrl}
                imageUrlForLargeDevice={this.tournamentImageUrl}
                smallDeviceImageMaxWidth={1540}
                largeDeviceImageMaxWidth={2880}
                ><div className={styles.selectDropDownWrapper}>
                <SelectDropdown selectedListItem={this.state.selectLeague} 
                                list={this.selectOptions} 
                                onChange={(e,li) => this.setState({ selectLeague : li }, () => this.sessionUpdate())}
                                selectBtnHtml={(props) => this.getSelectedBtnHtml(props)} />
            </div></UiBkgImage> : <div className={styles.image__imageWrapper}><div className={styles.selectDropDownWrapper}>
                <SelectDropdown selectedListItem={this.state.selectLeague} 
                                list={this.selectOptions} 
                                onChange={(e,li) => this.setState({ selectLeague : li })}
                                selectBtnHtml={(props) => this.getSelectedBtnHtml(props)} />
                </div></div>
          }
        </div>
            
          
          
        </div>
        <div className={styles.matchListOddsWrapper}>
        <MatchListOdds 
                key={this.state.selectLeague.value}
                sportId={this.sportId}
                tournamentId={this.state.selectLeague.value}
          />
        </div>

        
      </section>
    );
  }
}

export const fragmentOddsComparsionBlock = graphql`
  fragment OddsComparsionBlock on ContentfulOddsComparsionBlock {
    id
    sport {
      sportId
      tournaments {
        tournamentName
        tournamentId
        inActivate
      }
      image{
        file{
          url
        }
      }
    }
  }
`;