import SiteMetaData from '../../../site-metadata';
import ImageFinderHelper from 'components/helpers/image-finder-helper.js';
import LanguagerHelper from 'components/helpers/language-helper';

class FaceBookHelper{
    
    getFacebookImageByCurrentBlocks(blocks){
        const url = ImageFinderHelper.getImageFromBlocks(blocks);

        return this.extendContentfulUrlWithHttps(url);
    }

    extendContentfulUrlWithHttps(url){
        return /^(\/\/)/.test(url) ? "https:" + url : url;
    }

    getFacebookLocale(){
        let locale = LanguagerHelper.getCurrentLocale();
        let facebookLocale = "";
        
        switch(locale) {
            case "sv": facebookLocale = "sv_SE"; break;
            case "en": facebookLocale = "en_US"; break;
            default : facebookLocale = "sv_SE";
        }
        
        return facebookLocale;
    }

    getFaceBookUrl(pageUrl){
        return SiteMetaData.siteUrl + pageUrl;
    }
}

const helper = new FaceBookHelper();
export default helper;