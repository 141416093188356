// more minimal version of https://github.com/olahol/scrollparent.js/blob/master/scrollparent.js
const regex = /(auto|scroll)/;

const style = (node, prop) =>
    getComputedStyle(node, null).getPropertyValue(prop);

const scroll = (node) =>
    regex.test(
        style(node, "overflow") +
        style(node, "overflow-y") +
        style(node, "overflow-x"));

/**
 * Recursivly try to find parent container with overflow scrolling enabled
 */
const scrollparent = (node) =>
    !node || node === document.body
        ? null
        : scroll(node)
            ? node
            : scrollparent(node.parentNode);

export default scrollparent;