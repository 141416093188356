import React, { Component } from 'react';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import get from 'lodash/get';
import styles from './card-block.module.less';
import { graphql, Link } from 'gatsby';

export default class CardBlock extends Component {
  constructor(props) {
    super(props);
    this.title = get(props, 'title');
    this.backgroundImage = get(props, 'backgroundImage.file.url');
    this.richText = get(props, 'richText.richText');
    this.title = get(props, 'title');
    this.buttonText = get(props, 'buttonText');
    this.internalLink = get(props, 'buttonLink.slug');
    this.isImageLeft = get(props, 'isImageLeft');
  }

  render() {
    var styleImage = this.backgroundImage && {
      backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3) ), url(${this.backgroundImage}?q=60)`,
    };
    return (
      <>
        {this.title && this.backgroundImage && this.richText && (
          <div className={styles.cardBlock}>
            <div className={styles.cardBlock__imageWrapper} style={styleImage} />
            <div className={styles.cardBlock__card}>
              <h1 className={styles.cardBlock__title}>{this.title}</h1>
              <div className={styles.richTextBlock}>{documentToReactComponents(JSON.parse(this.richText))}</div>
              {this.buttonText && this.internalLink && (
                <Link className={styles.cardBlock__button} to={this.internalLink}>
                  {this.buttonText.toUpperCase()}
                </Link>
              )}
            </div>
          </div>
        )}
      </>
    );
  }
}

export const fragmentCardBlock = graphql`
  fragment CardBlock on ContentfulCardBlock {
    ... on ContentfulCardBlock {
      id
      backgroundImage {
        file {
          url
        }
      }
      title
      richText {
        id
        richText
      }
      isImageLeft
      buttonText
      buttonLink {
        slug
      }
      smallVersion
    }
  }
`;
