import React, { Component } from 'react'
import get from 'lodash/get'
import styles from './scroll-to-top-button.module.less'
import { graphql } from 'gatsby'

export default class  ScrollToTopButton extends Component {
  constructor(props) {
    super(props)
    this.showScrollToTopButton = get(props, 'showScrollToTopButton')
    this.state = { hasScrolled: false }
    this.scrollButton = React.createRef()
  }

  componentDidMount() {
    window.addEventListener('scroll', this.onScroll)
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.onScroll)
  }

  onScroll = () => {
    if (window.scrollY > 100 && !this.state.hasScrolled) {
      this.setState({ hasScrolled: true })
    } else if (window.scrollY < 100 && this.state.hasScrolled) {
      this.setState({ hasScrolled: false })
    }
  }

  scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' })
  }

  render() {
    return (
      <>
        {this.state.hasScrolled && (
          <button
            className={[styles.scrollToTopButton,"waves-effect"].join(" ")}
            onClick={this.scrollToTop}
            ref={this.scrollButton}
          >
            <i
              className={[
                'icon-keyboard_arrow_up',
                styles.scrollToTopButton__icon,
              ].join(' ')}
            />
          </button>
        )}
      </>
    )
  }
}

export const fragmentScrollToTopButton = graphql`
  fragment ScrollToTopButton on ContentfulScrollToTopButton {
    ... on ContentfulScrollToTopButton {
      id
      buttonText
    }
  }
`
