import React, { Component } from 'react';
import get from 'lodash/get';
import styles from './text-block.module.less';
import { graphql } from 'gatsby';

export default class TextBlock extends Component {
  constructor(props) {
    super(props);
    this.title = get(props, 'title');
    this.text = get(props, 'text.childMarkdownRemark.html');
    this.preamble = get(props, 'preamble.childMarkdownRemark.html');
    this.optionalBackgroundImage = get(props, 'optionalBackgroundImage.file.url');
  }

  render() {
    const styleImage = this.optionalBackgroundImage && {
      backgroundImage: `url(${this.optionalBackgroundImage}?q=60)`,
    };

    return (
      <section
        className={[
          'block__padded block__max-width',
          styles.textBlock,
          this.props.centerText ? styles._textCenter : '',
        ].join(' ')}
        style={styleImage}
        data-contentful-id={this.props.contentful_id}>
        <div className={[styles.textBlock__textWrapper].join(' ')}>
          {this.title && <h2 className={['h1', styles.textBlock__title].join(' ')}>{this.title}</h2>}
          {this.preamble ? (
            <div className={[styles.preamble].join(' ')} dangerouslySetInnerHTML={{ __html: this.preamble }} />
          ) : (
            ''
          )}
          {this.text ? (
            <div className={[styles.textBlock__text].join(' ')} dangerouslySetInnerHTML={{ __html: this.text }} />
          ) : (
            ''
          )}
        </div>
      </section>
    );
  }
}

export const fragmentTextBlock = graphql`
  fragment TextBlock on ContentfulTextBlock {
    ... on ContentfulTextBlock {
      id
      contentful_id
      title
      text {
        childMarkdownRemark {
          html
        }
      }
      preamble {
        childMarkdownRemark {
          html
        }
      }
      centerText
    }
  }
`;
