import React, { useEffect, useState } from 'react';

const CircularProgress = ({
  size,
  strokeWidth,
  percentage,
  color,
  isBallPercent = false,
  text,
  text1,
  text2,
  mobile,
  little,
  percentagePlus,
}) => {
  const [progress, setProgress] = useState(0);
  useEffect(() => {
    percentagePlus
      ? percentage > 90
        ? setProgress(percentage + 1.5)
        : setProgress(percentage + 5.5)
      : setProgress(percentage);
  }, [percentage]);

  const viewBox = `0 0 ${size} ${size}`;
  const radius = (size - strokeWidth) / 2;
  const circumference = radius * Math.PI * 2;
  const dash =
    parseFloat(progress) >= 99.9
      ? ((parseFloat(progress) + 3) * circumference) / 100
      : (parseFloat(progress) * circumference) / 100;

  return (
    <svg width={size} height={size} viewBox={viewBox}>
      <circle
        fill='none'
        stroke={isBallPercent ? (percentage <= 50 || percentage == 0 ? color : '#eac1c7') : '#CA6978'}
        cx={size / 2}
        cy={size / 2}
        strokeLinecap='butt'
        strokeOpacity='1'
        r={radius}
        strokeWidth={!isBallPercent ? `1px` : strokeWidth}
      />
      {percentage > 0 ? (
        <circle
          fill='none'
          stroke={isBallPercent ? (percentage <= 50 && percentage != 0 ? '#eac1c7' : color) : color}
          strokeOpacity='1'
          cx={size / 2}
          cy={size / 2}
          r={radius}
          strokeWidth={`${strokeWidth}px`}
          transform={`rotate(-90 ${size / 2} ${size / 2})`}
          strokeDashoffset={isBallPercent ? -(strokeWidth / 2) : 0}
          strokeDasharray={[dash - strokeWidth > 0 ? dash - strokeWidth : dash, circumference - dash + strokeWidth]}
          strokeLinecap='square'
          style={{ transition: 'all 0.5s' }}
        />
      ) : null}

      {isBallPercent ? (
        <>
          <circle
            fill='none'
            stroke='white'
            cx={size / 2}
            cy={size / 2}
            r={radius}
            strokeWidth={strokeWidth / 3 + 1}
            transform={`rotate(-90 ${size / 2} ${size / 2})`}
            strokeDasharray={[1, 520]}
            strokeLinecap='square'
            style={{ transition: 'all 0.5s' }}
          />

          <circle
            fill='none'
            stroke='white'
            cx={size / 2 + strokeWidth / 3}
            cy={size / 2}
            r={radius}
            strokeWidth={strokeWidth / 3 + 1}
            transform={`rotate(-90 ${size / 2} ${size / 2})`}
            strokeDasharray={[1, 520]}
            strokeLinecap='square'
            style={{ transition: 'all 0.5s' }}
          />

          <circle
            fill='none'
            stroke='white'
            cx={size / 2 - strokeWidth / 3}
            cy={size / 2}
            r={radius}
            strokeWidth={strokeWidth / 3 + 1}
            transform={`rotate(-90 ${size / 2} ${size / 2})`}
            strokeDasharray={[1, 520]}
            strokeLinecap='square'
            style={{ transition: 'all 0.5s' }}
          />

          <circle
            fill='none'
            stroke='white'
            cx={size / 2}
            cy={size / 2}
            r={radius}
            strokeWidth={strokeWidth / 3 + 1}
            transform={`rotate(${(dash * 360) / (2 * Math.PI * radius) - 90} ${size / 2} ${size / 2})`}
            strokeDasharray={[1, 520]}
            strokeLinecap='square'
            style={{ transition: 'all 0.5s' }}
          />

          <circle
            fill='none'
            stroke='white'
            cx={size / 2 + strokeWidth / 3}
            cy={size / 2}
            r={radius}
            strokeWidth={strokeWidth / 3 + 1}
            transform={`rotate(${(dash * 360) / (2 * Math.PI * radius) - 90} ${size / 2} ${size / 2})`}
            strokeDasharray={[1, 520]}
            strokeLinecap='square'
            style={{ transition: 'all 0.5s' }}
          />

          <circle
            fill='none'
            stroke='white'
            cx={size / 2 - strokeWidth / 3}
            cy={size / 2}
            r={radius}
            strokeWidth={strokeWidth / 3 + 1}
            transform={`rotate(${(dash * 360) / (2 * Math.PI * radius) - 90} ${size / 2} ${size / 2})`}
            strokeDasharray={[1, 520]}
            strokeLinecap='square'
            style={{ transition: 'all 0.5s' }}
          />
        </>
      ) : null}
      <text
        fill={!text ? 'black' : '#888888'}
        fontSize={!text ? (little ? '10px' : '1.125rem') : mobile ? '.7rem' : '24px'}
        fontFamily={!text ? null : 'docu-bold'}
        fontWeight={!text ? null : 'bold'}
        color={!text ? 'black' : '#8e9cba'}
        x='50%'
        y={!text ? (little ? '30%' : '40%') : text1 ? (mobile ? (text2 ? '20%' : '30%') : '38%') : '43%'}
        dy='20px'
        textAnchor='middle'
        width={20}>
        {`${text ?? `${percentage}`}`}
      </text>
      {text1 ? (
        <text
          fill={!text1 ? 'black' : '#888888'}
          fontSize={!text ? '2rem' : mobile ? '.7rem' : '24px'}
          fontFamily={!text ? null : 'docu-bold'}
          fontWeight={!text ? null : 'bold'}
          color={!text ? 'black' : '#8e9cba'}
          x='50%'
          y={mobile ? (text2 ? '32%' : '40%') : '50%'}
          dy='20px'
          textAnchor='middle'
          width={20}>
          {`${text1 ?? `${percentage}`}`}
        </text>
      ) : null}
      {text2 ? (
        <text
          fill={!text2 ? 'black' : '#888888'}
          fontSize={!text1 ? '2rem' : mobile ? '.7rem' : '1.1rem'}
          fontFamily={!text1 ? null : 'docu-bold'}
          fontWeight={!text1 ? null : 'bold'}
          color={!text1 ? 'black' : '#8e9cba'}
          x='50%'
          y={mobile ? '44%' : '54%'}
          dy='20px'
          textAnchor='middle'
          width={20}>
          {`${text2 ?? `${percentage}`}`}
        </text>
      ) : null}
    </svg>
  );
};

export default CircularProgress;
