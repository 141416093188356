import React, { Component } from 'react';
import { graphql } from 'gatsby';
import get from 'lodash/get';
import styles from './hero-block.module.less';

export default class HeroBlock extends Component {
  constructor(props) {
    super(props);
    this.image = get(props, 'image.file.url');
    this.paragraph = get(props, 'paragraph');
    this.opacity = ( this.props.opacity === 0 || (this.props.opacity > 0 && this.props.opacity <= 100)) ? this.props.opacity : null;
    this.state = {
      paragraph: false,
    };
  }

  render() {
    let bkgImage;
    let customOpacity;

    if(this.image){
      
      if( this.props.opacity === 0 || (this.props.opacity > 0 && this.props.opacity <= 100)){
        bkgImage = { backgroundImage : `url(${this.image}?q=60)` };
        customOpacity = { backgroundColor : `rgba(0, 0, 0, ${this.opacity/100})`};
      }
      else{
        bkgImage = { backgroundImage: `linear-gradient(to right, rgba(0, 0, 0, 0.7) 0%, rgba(0, 0, 0, 0.2) 80%, rgba(0, 0, 0, 0) 100%), url(${this.image}?q=60)`}
      }
    }


    return (
      <>
        {this.image && this.paragraph && (
          <section className={styles.hero} style={bkgImage}>
            {
              customOpacity ? <div className={styles.customOpacity} style={customOpacity}></div> : ""
            }
            <div className={styles.heroContent}>
              {!this.state.paragraph ? <h2 className={styles.heroParagraph}>{this.paragraph}</h2> : ''}
            </div>
          </section>
        )}
      </>
    );
  }
}

export const fragmentHeroBlock = graphql`
  fragment HeroBlock on ContentfulHeroBlock {
    ... on ContentfulHeroBlock {
      id
      image {
        file {
          url
        }
      }
      paragraph
      opacity
    }
  }
`;
