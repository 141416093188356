import SiteMetaData from '../../../site-metadata';

class LanguageHelper {
    currentLocale;

    constructor() {
        this.currentLocale = SiteMetaData.locales.find((locale) => { return locale.default });
    }

    setLocale(locale) {
        this.currentLocale = locale;
    }

    getCurrentLocale() {
        return this.currentLocale;
    }
}

const helper = new LanguageHelper();
export default helper;