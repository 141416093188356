import styles from './standings-bookings-league.module.less';
import React, { Component } from 'react';
import LogoHelper from 'components/helpers/logo-helper';
import TranslationStore from 'components/stores/translation-store';
import UiBtn from 'components/block-elements/ui-btn/ui-btn';
import plusIcon from 'static/plus.png'

export default class StandingsBookingsLeague extends Component {
  constructor(props) {
    super(props);

    this.standings = this.props.standings instanceof Array ? this.props.standings : [];
    this.playerLabel = TranslationStore.getTranslation("standings_booking_league_player");
    this.teamLabel = TranslationStore.getTranslation("standings_booking_league_team");
    this.pointsLabel = TranslationStore.getTranslation("standings_booking_league_points");
    this.showMoreLabel = TranslationStore.getTranslation("standings_booking_league_show_more");
    this.backdropClass = LogoHelper.getTournamentBackdropClass(this.props.tournamentId);
    // this.updatedStandings = this.standings.sort((a,b) => a.rankCardPoints < b.rankCardPoints ? -1 : a.rankCardPoints > b.rankCardPoints ? 1 : 0)
    this.state = {
      itemsToShow: 10,
      standings: this.props.standings instanceof Array ? this.props.standings : []
    };
  }

  addRanks(sortedStandings) {
    const standingsWithRanks = [];
    let rank = 1;
    standingsWithRanks.push({ ...sortedStandings[0], bookingPointsRank: rank });

    for (let i = 1; i < sortedStandings.length; i++) {
      if (sortedStandings[i].bookingPoints === sortedStandings[i - 1].bookingPoints) {
        standingsWithRanks.push({ ...sortedStandings[i], bookingPointsRank: rank });
      } else {
        rank = i + 1;
        standingsWithRanks.push({ ...sortedStandings[i], bookingPointsRank: rank });
      }
    }
    return standingsWithRanks;
  }

  componentDidMount() {
    this.standings.map(el => 
      el.bookingPoints = el.yellowCards + (el.yellowRedCards * 2) + (el.redCards * 3));

      this.standings.sort(function (a, b) {
        if (a.yellowCards + (a.yellowRedCards * 2) + (a.redCards * 3)  < b.yellowCards + (b.yellowRedCards * 2) + (b.redCards * 3)) {
          return 1;
        }
        if (a.yellowCards + (a.yellowRedCards * 2) + (a.redCards * 3)  > b.yellowCards + (b.yellowRedCards * 2) + (b.redCards * 3)) {
          return -1;
        }

        return 0;
      });

      this.setState({standings: this.addRanks(this.standings)});
  }

  render() {
    return (
      <>
        <div className={[styles.wrapper].join(' ')}>
          {this.state.standings && this.state.standings.length > 0 ? <table className={styles.standingsList__table}>
            <thead>
              <tr className={styles.standingsList__tableTitles}>
                {/* eslint-disable-next-line */}
                <th className={styles.standingsList__rankHeader}>#</th>
                {this.playerLabel && <th className={styles.standingsList__left}>{this.playerLabel}</th>}
                {this.teamLabel && <th className={styles.standingsList__left}>{this.teamLabel}</th>}
                <th className={styles.standingsList__left}>
                  <img src={'/images/injured-icons/booked.svg'} alt={''} />
                </th>
                <th className={styles.standingsList__left}>
                  <img src={'/images/injured-icons/yellow-red-card.svg'} alt={''} />
                </th>
                <th className={styles.standingsList__left}>
                  <img src={'/images/injured-icons/suspended.svg'} alt={''} />
                </th>
                {this.pointsLabel && <th className={styles.standingsList__center}>{this.pointsLabel}</th>}
              </tr>
            </thead>
            <tbody className={styles.standingsList__body}>
              {this.state.standings.slice(0, this.state.itemsToShow).map((player, index) => {
                return (
                  <tr
                    className={[styles.standingsList__row, index % 2 !== 0 ? styles.standingsList__rowUneven : ''].join(
                      ' '
                    )}
                    key={player.playerId}>
                    <td className={[styles.standingsList__center, styles.standingsList__rank].join(' ')}>
                      {player.bookingPointsRank}
                    </td>
                    <td className={styles.standingsList__left}>{player.player}</td>
                    <td className={styles.standingsList__left}>{player.team}</td>
                    <td className={styles.standingsList__left}>{player.yellowCards}</td>
                    <td className={styles.standingsList__left}>{player.yellowRedCards}</td>
                    <td className={styles.standingsList__left}>{player.redCards}</td>
                    <td className={[styles.standingsList__center, styles.standingsList__points].join(' ')}>
                      {player.yellowCards + player.yellowRedCards * 2 + player.redCards * 3}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table> : <div style={{textAlign: 'center'}}>
            <h3>Ingen information tillgänglig i denna liga</h3></div>}
          {/* {this.state.standings.length > this.state.itemsToShow && (
            <div className={styles.align__center}>
            <UiBtn
              style={{fontSize: '2.5rem', width: '3rem', padding: '0', fontFamily: 'icomoon', lineHeight: '2.5rem'}}
              onClick={() => this.setState({ itemsToShow: this.state.itemsToShow + 10 })}
              className={'ui-btn--medium ui-btn--primary waves-effect'}
              label="+"
              // label={this.showMoreLabel}
            />
            <div style={{marginTop: '5px', fontSize: '0.75rem', color: 'grey'}}><span>{this.showMoreLabel}</span></div>
          </div>
          )} */}

        {this.standings.length > this.state.itemsToShow && (
          <div style={{marginTop: '1rem', marginBottom: '1rem'}}>
            <div style={{display: 'flex', justifyContent: 'center'}}>
              <div 
                className={'waves-effect'} 
                onClick={() => this.setState({ itemsToShow: this.state.itemsToShow + 10 })}
                style={{minWidth: '3rem', minHeight: '3rem', background: '#00c197', borderRadius: '0.375rem', justifyContent: 'center', alignSelf: 'center'}}>
                  <div style={{display: 'flex', justifyContent: 'center', fontFamily: 'icomoon', fontSize: '2.5rem', height: '3rem', alignItems: 'center', color: 'white'}}>
                    <img width={35} height={35} src={plusIcon}></img>
                  </div>
                </div>
            </div>
            <div style={{display: 'flex', justifyContent: 'center'}}>
              <div style={{marginTop: '5px', fontSize: '0.75rem', color: 'grey'}}><span>{this.showMoreLabel}</span></div>
            </div>
          </div>)}
        </div>
        <div className={styles.errorMessage}>
          {this.state.standings.length === 0 && (
            <span className={styles.errorMessageText}>Fanns ingen data för denna match/liga.</span>
          )}
        </div>
      </>
    );
  }
}
