import React, { Component } from 'react';
import './ui-radio.module.less';

export default class UiRadio extends Component {

    isRadioBtnChecked(index){
        if(!(this.props.selectedListItem instanceof Object)){
            return false;
        }

        return this.props.list[index].value === this.props.selectedListItem.value && 
               this.props.list[index].label === this.props.selectedListItem.label ? true : false;
    }

    getId() {
        this.id = this.props.id ? this.props.id : this.id ? this.id : "radio_" + Math.floor((Math.random() * 1000000) + 1);
        return this.id;
    }

    onToggleRadioList(){
        if(typeof this.props.onToggleRadioList === 'function') {
            this.props.onToggleRadioList();
        }
    }

    onChange(e,index){
        if(typeof this.props.onChange === 'function') {
            this.props.onChange(e,this.props.list[index]);
        }
    }

    removeSelectedListItemFromRadioList(index){
        return this.props.radioListRemoveSelectedListItemFromList && this.isRadioBtnChecked(index) ? true : false;
    }

    render() {
        const RadioBtn = this.props.radioBtnHtml ? this.props.radioBtnHtml : "";
        

        return  <div className={['ui-dropdown-radio__wrapper',this.props.className ? this.props.className : ""].join(" ")}>
                {
                    RadioBtn &&
                    <button onClick={() => this.onToggleRadioList()}
                            className={['ui-dropdown-radio__btn',this.props.radioListVisible ? 'ui-dropdown-radio__btn--expanded':''].join(" ")}><RadioBtn {...this.props.radioBtnHtml} /></button>
                }
                {
                    this.props.radioListVisible
                }
                {
                    this.props.radioListVisible &&
                    <ul id={this.getId()}
                        className={'ui-dropdown-radio__list'}>{ 
                            this.props.list.map((li,i) => {
                                const RadioListItemHtml = this.props.radioListItemHtml ? this.props.radioListItemHtml : "";
                                
                                return this.removeSelectedListItemFromRadioList(i) ? "" :
                                li.listSeparator ? 
                                <li key={this.getId() + "_" + i} className={['ui-dropdown-radio__list-item', li.className ? li.className : ""].join(" ")}>{li.label}</li> :
                                <li key={this.getId() + "_" + i} 
                                            className={['ui-dropdown-radio__list-item',this.isRadioBtnChecked(i) ? 'ui-dropdown-radio__list-item--active':''].join(" ")}>
                                    <label htmlFor={this.getId() + "_" + i}>
                                        <input  type={'radio'} 
                                                id={this.getId() + "_" + i} 
                                                className={'ui-dropdown-radio__input'}
                                                name={this.getId() + "_group"} 
                                                checked={this.isRadioBtnChecked(i)} 
                                                value={li.value}
                                                disabled={li.isDisabled === true ? true : false}
                                                onChange={(e) => this.onChange(e,i)} />
                                                { RadioListItemHtml ? <RadioListItemHtml {...li} /> : li.label }
                                                
                                    </label>  
                                </li>
                            })
                        }
                    </ul>
                }
                </div>
    }
}