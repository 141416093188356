import React, { Component } from 'react';
import get from 'lodash/get';
import SportEventService from 'services/sport-event-service';
import DateHelper from  'components/helpers/date-helper'
import LiveScoreMatchItem from 'components/block-elements/live-score-match-item/live-score-match-item';
import MatchStore from 'components/stores/match-store'
import SessionStorageHelper from 'components/helpers/session-storage-helper'
import TranslationStore from 'components/stores/translation-store';
import Loader from 'components/block-elements/loader-css/loader-css';
import styles from './match-list-odds.module.less';

export default class MatchListOdds extends Component {

  constructor(props) {
    super(props);
    this.matches = []
    this.today = DateHelper.moment().format("YYYY-MM-DD")
    this.weekFromToday = DateHelper.moment().add(7, 'd').format('YYYY-MM-DD');
    this.state = {
      list : []
    }

    this.sessionCachKey = "match_list_odds_" + this.props.sportId + "_" + this.props.tournamentId
  }

  componentDidMount(){
    MatchStore.getMatches()
    .then(matches => this.matches = matches.filter(match => match.status !== "cancelled"))
    .then(() => this.setMatchListFromCacheIfAvailable())
    .then(() => this.getList(this.getMatchListFromCache() ? false : true ))
  }

  getMatchListFromCache(){
    if(typeof window === 'undefined'){
      return null;
    }

    return SessionStorageHelper.getItem(this.sessionCachKey) ? SessionStorageHelper.getItem(this.sessionCachKey) : null
  }

  getCacheExpirationTimeStamp(){
    let now = new Date();
    return now.setMinutes(now.getMinutes() + 120)
  }

  setMatchListFromCacheIfAvailable(){
    if(this.getMatchListFromCache()){
      this.setState({
        list : SessionStorageHelper.getItem(this.sessionCachKey)
      })
    }
  }

  getList(isLoading=true){
    if(this.props.tournamentId && this.props.sportId){
      
      this.setState({ isLoading : isLoading })
      
      SportEventService.sportEventCalendar(`?fromDate=${this.today}&toDate=${this.weekFromToday}&sportId=${this.props.sportId}&tournamentId=${this.props.tournamentId}`)
      .then(res => {
        res = res instanceof Array ? res.filter(item => item.status !== "postponed") : res;
        
        return MatchStore.getMatches()
        .then(matches => {
          if(matches instanceof Array && res instanceof Array){
            res = res.filter(item => !matches.find(match => match.matchId === item.id && match.isHidden === true));
          }

          res = res.map(item => {
            let scheduled = item.scheduled.charAt(item.scheduled.length - 1) === 'Z' ? item.scheduled : item.scheduled + "Z";
  
            return {
            ...item,
            scheduledFormat : DateHelper.moment(scheduled).format("D MMM")
          }})
          this.setState({ list : res , isLoading : false }, () =>  SessionStorageHelper.setItem(this.sessionCachKey, res))
        })

      })
      .catch(e => {
        this.setState({ isLoading : false });
      })
    }
  }

  render(){
    let lastDate = "";
    let listItemHeader = ""
    
    if(this.state.isLoading){
        return <div className={styles.noResultWrapper}><ul className={styles.list}><li><div className={'loader-container'}><Loader /></div></li></ul></div>
    }

    return this.state.list.length ? 
    <ul className={styles.list}>
      {
        this.state.list.map(li => {
          listItemHeader = li.scheduled.substr(0, 10) === lastDate ? "" : <div className={styles.dateGroupHeader}>{li.scheduledFormat}</div>
          lastDate = li.scheduled.substr(0, 10);
          let contentfulMatch = this.matches.find(match => match.matchId === li.id);
          let tvLogo = contentfulMatch ? get(contentfulMatch,"tvLogo.file.url","") : "";
          
          return (
            <li key={li.id}>
              {
                listItemHeader 
              }
              <LiveScoreMatchItem selectMatchTabByAlias={"oddsComparsion"} {...li}  tvLogo={{ file : { url : tvLogo }}} />
            </li>
          )
      })
      }
      
    </ul> : <div className={styles.noResultWrapper}><ul className={styles.list}><li><div className={styles.dateGroupHeader}>{TranslationStore.getTranslation("match_list_odds_no_matches_available")}</div></li></ul></div>
  }
}