import React from 'react';
import get from 'lodash/get';
import { graphql, Link } from 'gatsby';
import styles from './breadcrumb.module.less';
import LinkWrapper from 'components/block-elements/link-wrapper/link-wrapper';

const Breadcrumb = props => {
  const pages = get(props, 'pages', []);
  const isMatch = get(props, 'isMatch', false);
  const isMcmNewsPage = get(props, 'isMcmNewsPage', false);

  const goBack = () => {
  }


  return (
    <div className={styles.wrapper}>
      {
      (isMatch || isMcmNewsPage) ? 
      <>
      {/* eslint-disable-next-line */}

      {typeof window !== 'undefined' && <div className={styles.arrow} onClick={() =>  window.history.back()}>
        <span className={'icon-keyboard_arrow_left'}></span>
      </div>}</> : ''}
      <ul className={[styles.list, !isMatch ? styles._withMargin : ''].join(' ')}>
        {pages.map((page, index) => (
          <li key={index} className={styles.item}>
            <LinkWrapper link={page.slug} className={styles.link}>
              {page.title}
            </LinkWrapper>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default Breadcrumb;

export const fragmentBreadcrumb = graphql`
  fragment Breadcrumb on ContentfulContentPage {
    id
    title
    slug
  }
`;
