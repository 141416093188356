import React, { Component } from 'react';
import get from 'lodash/get';
import { graphql } from 'gatsby';
import LiveScoreContext from 'components/stores/live-matches-context';
import MatchHelper from 'components/helpers/match-helper';
import { minutesToMs, formatTimestampAsZulu, UTCToLocalDate } from 'components/helpers/date-helper';
import LiveScoreMatchItem from 'components/block-elements/live-score-match-item/live-score-match-item';
import DateHelper from 'components/helpers/date-helper';
import Loader from 'components/block-elements/loader-css/loader-css';
import UiBtn from 'components/block-elements/ui-btn/ui-btn';
import TranslationStore from 'components/stores/translation-store';
import SelectDropdown from 'components/block-elements/select-dropdown/select-dropdown';
import SessionStorageHelper from 'components/helpers/session-storage-helper';
import styles from './live-score-block.module.less';
import TeamColors from 'static/TeamTableColors.json';
import axios from 'components/helpers/axios';

export default class LiveScoreBlock extends Component {
  constructor(props) {
    super(props);
    this.allMatches = get(this.props, 'allContentfulMatch.edges');
    this.today = TranslationStore.getTranslation('live_score_block_today');
    this.loadingMessage = TranslationStore.getTranslation('live_score_block_loading');
    this.comingMatchesHeader = TranslationStore.getTranslation('live_score_block_coming_matches_header');
    this.sortOptions = [
      { label: TranslationStore.getTranslation('live_score_block_sort_tournament'), value: 'tournament' },
      { label: TranslationStore.getTranslation('live_score_block_sort_time'), value: 'time' },
    ];
    this.state = {
      showDateList: false,
      matchesByTournament: [],
      selectedSort:
        typeof window !== 'undefined' && SessionStorageHelper.getItem('live_score_block_sort_type')
          ? SessionStorageHelper.getItem('live_score_block_sort_type')
          : this.sortOptions[0],
    };
  }

  isMatchRecentlyClosed(match) {
    const now = new Date();

    if (MatchHelper.isMatchClosed(match.status)) {
      const matchEndDate = this.getMatchEndTime(match);
      const thirtyMinutesAgo = DateHelper.moment(now.getTime() - minutesToMs(35))
        .utc()
        .format();

      if (matchEndDate.valueOf() >= thirtyMinutesAgo.valueOf()) {
        return true;
      }
    }
    return false;
  }

  getCancelledMatches(matches) {
    return matches.filter((match) => match.status === 'cancelled');
  }

  getLiveOrRecentlyClosedMatches(matches) {
    return matches.filter((match) =>
      MatchHelper.isMatchLive(match.status) ? true : this.isMatchRecentlyClosed(match)
    );
  }

  getClosedMatches(matches) {
    return matches.filter((match) => MatchHelper.isMatchClosed(match.status) && !this.isMatchRecentlyClosed(match));
  }

  getComingMatches(matches) {
    return matches.filter(
      (match) =>
        !MatchHelper.isMatchClosed(match.status) &&
        !MatchHelper.isMatchLive(match.status) &&
        !this.isMatchRecentlyClosed(match)
    );
  }

  getMatchEndTime(match) {
    if (match.status === 'closed' && match.latestEventTime) {
      return formatTimestampAsZulu(match.latestEventTime);
    }
    const matchDate = UTCToLocalDate(match.scheduled);
    const matchEndDate = new Date(matchDate);
    return matchEndDate.setHours(matchDate.getHours() + 2);
  }

  async componentDidMount() {
    window.scrollTo(0, 0);
  }

  isValidTournament(tournament) {
    return tournament && get(tournament, 'flagIcon.file.url', false);
  }

  getSortedByTournament(matches) {
    if (this.props.tournamentSortOrder instanceof Array && this.props.tournamentSortOrder.length) {
      let sortOrder = this.props.tournamentSortOrder.map((tournament) => tournament.tournamentId);
      matches.sort((a, b) => {
        let indexOfA = sortOrder.indexOf(a.tournamentId);
        let indexOfB = sortOrder.indexOf(b.tournamentId);

        if (indexOfA === -1 && indexOfB === -1) {
          return 0;
        }

        if (indexOfA === -1) {
          return 1;
        }

        if (indexOfB === -1) {
          return -1;
        }

        return indexOfA - indexOfB;
      });
    }

    return matches;
  }

  getSortBtnHtml(props) {
    return this.state.selectedSort instanceof Object ? (
      <>
        <span className={[styles.sortLabel].join(' ')}>{props.label}</span>
        <span className={[styles.sortIcon, 'icon-sort-amount-desc'].join(' ')}></span>
      </>
    ) : (
      ''
    );
  }

  sessionUpdate() {
    SessionStorageHelper.setItem('live_score_block_sort_type', this.state.selectedSort);
  }

  handleDateChange(dayIndex) {
    if (dayIndex !== 0) {
      let newStartDate = DateHelper.moment().add(dayIndex, 'd').startOf('day').format();
      let newEndDate = DateHelper.moment().add(dayIndex, 'd').endOf('day').format();
      if (dayIndex !== 0 && this.context.fromDate !== DateHelper.moment.parseZone(newStartDate).utc().format()) {
        this.context.fetchMatches(
          DateHelper.moment.parseZone(newStartDate).utc().format(),
          DateHelper.moment.parseZone(newEndDate).utc().format(),
          true
        );
      }
    }

    if (dayIndex === 0) {
      let newStartDate = DateHelper.moment().startOf('day').format();
      let newEndDate = DateHelper.moment().endOf('day').format();
      if (this.context.fromDate !== DateHelper.moment.parseZone(newStartDate).utc().format()) {
        this.context.fetchMatches(
          DateHelper.moment.parseZone(newStartDate).utc().format(),
          DateHelper.moment.parseZone(newEndDate).utc().format(),
          true
        );
      }
    }
  }

  onTabBtnClick(direction) {
    let tabKey = this.context.getDateTabIndex() + direction;
    if (tabKey > 2) {
      tabKey = 2;
    } else if (tabKey < -2) {
      tabKey = -2;
    }
    this.handleDateChange(tabKey);
  }

  render() {
    if (!this.context || this.context.sr_matches === null) {
      return (
        <div className={styles.liveScoreWrapper}>
          <div className={'loader-container'}>
            <Loader />
          </div>
        </div>
      );
    }
    const sportId =
      this.props.sports instanceof Array && this.props.sports.length === 1 ? this.props.sports[0].sportId : null;
    let matches = sportId
      ? this.context.sr_matches.filter((match) => match.sportId === sportId)
      : this.context.sr_matches;
    let liveAndRecentlyClosedMatches = [];
    let closedMatches = [];
    let comingMatches = [];
    let allMatches = [];
    let isTodaySelected =
      typeof window !== 'undefined'
        ? DateHelper.moment(this.context.fromDate).isSame(DateHelper.moment(), 'day')
        : false;
    matches = matches.filter((match) => match.status !== 'cancelled');
    let dateIndexIsToday = this.context.getDateTabIndex() === 0 ? true : false;
    let currentDateTabIndex = this.context.getDateTabIndex();

    if (this.state.selectedSort && this.state.selectedSort.value === 'tournament') {
      allMatches = this.getSortedByTournament(matches);

      if (!isTodaySelected) {
        allMatches = allMatches.filter((match) => !MatchHelper.isMatchLive(match.status));
      }
    } else {
      liveAndRecentlyClosedMatches = this.getLiveOrRecentlyClosedMatches(matches);
      closedMatches = this.getClosedMatches(matches);
      comingMatches = this.getComingMatches(matches);
    }

    return (
      <>
        <div
          className={[
            'block__padded block__max-width-narrow',
            styles.dateSelectionListWrapper,
            this.state.showDateList ? styles.showDateList : styles.hideDateList,
          ].join(' ')}>
          <ul className={styles.dateSelectionList}>
            <li className={styles.dateSelectionListItem}>
              <UiBtn
                className={[
                  'ui-btn ui-btn--medium ui-btn--full-width ui-btn--transparent ui-btn--no-border-radius waves-effect',
                  this.context.fromDate ===
                  DateHelper.moment.parseZone(DateHelper.moment().add(-2, 'd').startOf('day')).utc().format()
                    ? styles.active
                    : styles.notActive,
                ].join(' ')}
                label={
                  DateHelper.moment().add(-2, 'd').format('ddd') + ' ' + DateHelper.moment().add(-2, 'd').format('D/M')
                }
                isDisabled={this.context.isLoading}
                onClick={() => this.handleDateChange(-2)}
              />
            </li>
            <li className={styles.dateSelectionListItem}>
              <UiBtn
                className={[
                  'ui-btn ui-btn--medium ui-btn--full-width ui-btn--transparent ui-btn--no-border-radius waves-effect',
                  this.context.fromDate ===
                  DateHelper.moment.parseZone(DateHelper.moment().add(-1, 'd').startOf('day')).utc().format()
                    ? styles.active
                    : styles.notActive,
                ].join(' ')}
                label={
                  DateHelper.moment().add(-1, 'd').format('ddd') + ' ' + DateHelper.moment().add(-1, 'd').format('D/M')
                }
                isDisabled={this.context.isLoading}
                onClick={() => this.handleDateChange(-1)}
              />
            </li>
            <li className={styles.dateSelectionListItem}>
              <UiBtn
                className={[
                  'ui-btn ui-btn--medium ui-btn--full-width ui-btn--transparent ui-btn--no-border-radius waves-effect',
                  this.context.fromDate ===
                  DateHelper.moment.parseZone(DateHelper.moment().startOf('day')).utc().format()
                    ? styles.active
                    : styles.notActive,
                ].join(' ')}
                label={this.today + ' ' + DateHelper.moment().format('D/M')}
                isDisabled={this.context.isLoading}
                onClick={() => this.handleDateChange(0)}
              />
            </li>
            <li className={styles.dateSelectionListItem}>
              <UiBtn
                className={[
                  'ui-btn ui-btn--medium ui-btn--full-width ui-btn--transparent ui-btn--no-border-radius waves-effect',
                  this.context.fromDate ===
                  DateHelper.moment.parseZone(DateHelper.moment().add(1, 'd').startOf('day')).utc().format()
                    ? styles.active
                    : styles.notActive,
                ].join(' ')}
                label={
                  DateHelper.moment().add(1, 'd').format('ddd') + ' ' + DateHelper.moment().add(1, 'd').format('D/M')
                }
                isDisabled={this.context.isLoading}
                onClick={() => this.handleDateChange(1)}
              />
            </li>
            <li className={styles.dateSelectionListItem}>
              <UiBtn
                className={[
                  'ui-btn ui-btn--medium ui-btn--full-width ui-btn--transparent ui-btn--no-border-radius waves-effect',
                  this.context.fromDate ===
                  DateHelper.moment.parseZone(DateHelper.moment().add(2, 'd').startOf('day')).utc().format()
                    ? styles.active
                    : styles.notActive,
                ].join(' ')}
                label={
                  DateHelper.moment().add(2, 'd').format('ddd') + ' ' + DateHelper.moment().add(2, 'd').format('D/M')
                }
                isDisabled={this.context.isLoading}
                onClick={() => this.handleDateChange(2)}
              />
            </li>
          </ul>
        </div>
        <div>
          <UiBtn
            className={[
              'ui-btn ui-btn--arrow-btn-small ui-btn--full-width ui-btn--transparent ui-btn--no-border-radius waves-effect',
              styles.arrowButtonLeft,
              currentDateTabIndex === -2 ? styles.arrowButtonDisabled : '',
            ].join(' ')}
            iconLeft={{ icon: 'icon-keyboard_arrow_left', align: 'center' }}
            onClick={() => this.onTabBtnClick(-1)}
          />

          <UiBtn
            className={[
              'ui-btn ui-btn--small ui-btn--full-width ui-btn--transparent ui-btn--no-border-radius waves-effect',
              styles.dateIcon,
              this.state.showDateList ? styles.showClose : styles.showDate,
            ].join(' ')}
            iconLeft={{ iconToggle: 'icon-ic-close', align: 'center' }}
            label={
              (dateIndexIsToday ? this.today : DateHelper.moment().add(currentDateTabIndex, 'd').format('ddd')) +
              ' ' +
              (dateIndexIsToday
                ? DateHelper.moment().format('D/M')
                : DateHelper.moment().add(currentDateTabIndex, 'd').format('D/M'))
            }
            onClick={() => {
              this.setState({ showDateList: !this.state.showDateList });
            }}
          />

          <UiBtn
            className={[
              'ui-btn ui-btn--arrow-btn-small ui-btn--full-width ui-btn--transparent ui-btn--no-border-radius waves-effect',
              styles.arrowButtonRight,
              currentDateTabIndex === 2 ? styles.arrowButtonDisabled : '',
            ].join(' ')}
            iconLeft={{ icon: 'icon-keyboard_arrow_right', align: 'center' }}
            onClick={() => this.onTabBtnClick(1)}
          />
        </div>
        <div className={styles.liveScoreWrapper}>
          <div className={[styles.selectSortOuterWrapper, 'block__padded block__max-width-narrow'].join(' ')}>
            <div className={styles.selectSortInnerWrapper}>
              <SelectDropdown
                selectedListItem={this.state.selectedSort}
                list={this.sortOptions}
                onChange={(e, li) => this.setState({ selectedSort: li }, () => this.sessionUpdate())}
                selectBtnHtml={(props) => this.getSortBtnHtml(props)}
              />
            </div>
          </div>
          {this.context.isLoading === true ? (
            <section data-contentful-id={this.props.contentful_id}>
              <div className={['block__padded block__max-width-narrow', styles.minHeightScreen].join(' ')}>
                <h2 className={styles.noLiveMatchesText}>
                  <div className={'loader-container'}>
                    <Loader />
                  </div>
                </h2>
              </div>
            </section>
          ) : (
            <section data-contentful-id={this.props.contentful_id}>
              <div className={'block__padded block__max-width-narrow'}>
                {this.state.selectedSort.value === 'tournament' ? (
                  <div className={styles.allMatchesList}>
                    {this.context?.sortingTournamentMatches?.length > 0 && this.context?.sortingTournamentMatches.filter(el => sportId ? el.sportId == sportId : true).map(
                      (el, index) => (
                        <div key={el.categoryId}>
                          <div className={[styles.tournamentGroupHeader, index === 0 ? styles.first : ''].join(' ')}>
                            <img src={el?.categoryLogo} className={styles.tournamentGroupHeaderImage} alt='' />
                            <span className={styles.tournamentGroupHeaderSport}>
                              {el.sportName == 'Soccer' ? 'Fotboll' : el.sportName}
                            </span>
                            <span className={styles.tournamentGroupHeaderSeparator}></span>
                            <span className={styles.tournamentGroupHeaderName}>{el?.categoryName}</span>
                          </div>
                          {el?.sportEvents.map(match =>
                            <LiveScoreMatchItem
                              key={match.id}
                              selectMatchTabByAlias={this.context.getChosenTab(match.id)}
                              {...match}
                            />
                          )}
                        </div>
                      )
                    )
                      }
                  </div>
                  
                ) : (
                  <>
                    {isTodaySelected && liveAndRecentlyClosedMatches.length ? (
                      <div className={[styles.matchesList, styles.liveAndRecentlyClosedMatches].join(' ')}>
                        {liveAndRecentlyClosedMatches.map((match, i) => {
                          const contentfulMatch = this.allMatches.find(
                            (item) => item && item.node && item.node.matchId === match.id
                          );
                          return contentfulMatch ? (
                            <LiveScoreMatchItem
                              selectMatchTabByAlias={this.context.getChosenTab(match.id)}
                              key={match.id}
                              {...match}
                              {...contentfulMatch.node}
                            />
                          ) : (
                            <LiveScoreMatchItem key={match.id} {...match} />
                          );
                        })}
                      </div>
                    ) : isTodaySelected ? (
                      <h2 className={styles.noLiveMatchesText}>{this.props.noLiveMatchesAvailable}</h2>
                    ) : (
                      ''
                    )}
                    {comingMatches.length ? (
                      <div className={styles.matchesList}>
                        <h2 className={styles.playedMatchesHeader}>{this.comingMatchesHeader}</h2>
                        {comingMatches.map((match, i) => {
                          const contentfulMatch = this.allMatches.find(
                            (item) => item && item.node && item.node.matchId === match.id
                          );
                          return contentfulMatch ? (
                            <LiveScoreMatchItem
                              selectMatchTabByAlias={this.context.getChosenTab(match.id)}
                              key={match.id}
                              {...match}
                              {...contentfulMatch.node}
                            />
                          ) : (
                            <LiveScoreMatchItem key={match.id} {...match} />
                          );
                        })}
                      </div>
                    ) : (
                      ''
                    )}
                    {closedMatches.length ? (
                      <div className={styles.matchesList}>
                        <h2 className={styles.playedMatchesHeader}>{this.props.closedMatches}</h2>
                        {closedMatches.map((match, i) => {
                          const contentfulMatch = this.allMatches.find(
                            (item) => item && item.node && item.node.matchId === match.id
                          );
                          return contentfulMatch ? (
                            <LiveScoreMatchItem
                              selectMatchTabByAlias={this.context.getChosenTab(match.id)}
                              key={match.id}
                              {...match}
                              {...contentfulMatch.node}
                            />
                          ) : (
                            <LiveScoreMatchItem key={match.id} {...match} />
                          );
                        })}
                      </div>
                    ) : (
                      ''
                    )}
                  </>
                )}
              </div>
              {allMatches?.length < 1 && <div className={styles.noMathcesToShowBlock}><span className={styles.noMathcesToShow}>Inga matcher spelas idag</span></div>}
            </section>
          )}
        </div>
      </>
    );
  }
}

LiveScoreBlock.contextType = LiveScoreContext;

export const fragmentLiveScoreBlock = graphql`
  fragment LiveScoreBlock on ContentfulLiveScoreBlock {
    contentful_id
    id
    title
    sports {
      sportId
      icon {
        file {
          url
        }
      }
    }
    noLiveMatchesAvailable
    closedMatches
    tournamentSortOrder {
      tournamentId
      tournamentName
      flagIcon {
        file {
          url
        }
      }
      country
    }
  }
`;
