import styles from './standings-bookings-league-hockey.module.less';
import React, { Component } from 'react';
import TranslationStore from 'components/stores/translation-store';
import UiBtn from 'components/block-elements/ui-btn/ui-btn';

export default class StandingsBookingsLeagueHockey extends Component {
  constructor(props) {
    super(props);

    TranslationStore.getTranslation("standings_booking_league_hockey_")
    this.standings = this.props.standings instanceof Array ? this.props.standings : [];

    this.playerLabel = TranslationStore.getTranslation("standings_booking_league_hockey_player");
    this.teamLabel = TranslationStore.getTranslation("standings_booking_league_hockey_team");
    this.penaltyMinutesLabel = TranslationStore.getTranslation("standings_booking_league_hockey_penalty_minutes");
    this.matchesPlayedLabel = TranslationStore.getTranslation("standings_booking_league_hockey_matches_played");
    this.showMoreLabel = TranslationStore.getTranslation("standings_booking_league_hockey_show_more");

    this.state = {
      itemsToShow: 10,
    };
  }

  render() {
    return (
      <>
        <div className={[styles.wrapper].join(' ')}>
          <table className={styles.standingsList__table}>
            <thead>
              <tr className={styles.standingsList__tableTitles}>
                {/* eslint-disable-next-line */}
                <th className={styles.standingsList__rankHeader} />
                {this.playerLabel && <th className={styles.standingsList__left}>{this.playerLabel}</th>}
                {this.teamLabel && <th className={styles.standingsList__left}>{this.teamLabel}</th>}
                {this.matchesPlayedLabel && <th className={styles.standingsList__left}>{this.matchesPlayedLabel}</th>}
                {this.penaltyMinutesLabel && <th className={styles.standingsList__left}>{this.penaltyMinutesLabel}</th>}
              </tr>
            </thead>
            <tbody className={styles.standingsList__body}>
              {this.standings.slice(0, this.state.itemsToShow).map((player, index) => {
                return (
                  <tr
                    className={[styles.standingsList__row, index % 2 !== 0 ? styles.standingsList__rowUneven : ''].join(
                      ' '
                    )}
                    key={player.id}>
                    <td className={[styles.standingsList__center, styles.standingsList__rank].join(' ')}>
                      {index + 1}
                    </td>
                    <td className={styles.standingsList__left}>{player.name}</td>
                    <td className={[styles.standingsList__left, styles.standingsList__team].join(' ')}>{player.competitorName}</td>
                    <td className={styles.standingsList__left}>{player.matchesPlayed}</td>
                    <td className={[styles.standingsList__left, styles.standingsList__penaltyMinutes].join(' ')}>
                      {player.penaltyMinutes}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
          {this.standings.length > this.state.itemsToShow && (
            <div className={styles.align__center}>
              <UiBtn
                onClick={() => this.setState({ itemsToShow: this.state.itemsToShow + 10 })}
                className={'ui-btn--medium ui-btn--primary waves-effect'}
                label={this.showMoreLabel}
              />
            </div>
          )}
        </div>
        <div className={styles.errorMessage}>
          {this.standings.length === 0 && (
            <span className={styles.errorMessageText}>Fanns ingen data för denna match/liga.</span>
          )}
        </div>
      </>
    );
  }
}
