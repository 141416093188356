import React, { Component } from 'react';
import get from 'lodash/get';
import styles from './landing-block.module.less';
import { graphql, Link } from 'gatsby';
// import iconArrowDown from '../../../styles/icons/icons/icon_double_arrow_down.svg';

export default class LandingBlock extends Component {
  constructor(props) {
    super(props);
    this.title = get(props, 'title');
    this.backgroundImage = get(props, 'backgroundImage.file.url');
    this.buttonText = get(props, 'buttonText');
    this.internalLink = get(props, 'internalLink.slug');
  }

  render() {
    let styleImage = this.backgroundImage && {
      backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.4) ),url(${this.backgroundImage}?q=60)`,
    };
    return (
      <>
        {this.title && this.backgroundImage && this.buttonText && this.internalLink && (
          <section className={styles.landingBlock}>
            <div className={styles.landingBlock__imageWrapper} style={styleImage}>
              <div className={styles.landingBlock__title}>{this.title.toUpperCase()}</div>
              <div className={styles.ctaButton}>
                <Link className={[styles.landingBlock__button,'waves-effect'].join(" ")} to={this.internalLink}>
                  {this.buttonText.toUpperCase()}
                </Link>
              </div>
            </div>
          </section>
        )}
      </>
    );
  }
}

export const fragmentLandingBlock = graphql`
  fragment LandingBlock on ContentfulLandingBlock {
    ... on ContentfulLandingBlock {
      id
      title
      backgroundImage {
        file {
          url
        }
      }
      buttonText
      internalLink {
        slug
      }
    }
  }
`;
