import React, { Component, useContext, useState } from 'react';
import { graphql } from 'gatsby';
import UserProfileService from 'services/user-profile-service';
import { AuthContext } from 'components/helpers/auth-context';
import styles from './login-block.module.less';
import { navigate } from 'gatsby';
import toast, { Toaster } from 'react-hot-toast';

const LoginBlock = (props) => {
  const auth = useContext(AuthContext);
  const [form, setForm] = useState({
    email: '',
    password: '',
  });

  const changeHandler = (event) => {
    setForm({ ...form, [event.target.name]: event.target.value });
  };

  const handleSubmit = async (event) => {
    await UserProfileService.login({
      email: form.email,
      password: form.password,
    })
      .then((res) => {
        auth.login(res.data);
        toast.success('Login succes!');
        navigate('/admin-panel');
      })
      .catch((err) => {
        toast.error(err.response.data);
      });
  };

  return (
    <>
      <section>
        <div className={styles.mainBlock}>
          <Toaster position='top-left' reverseOrder={true} />
          <div className={styles.mainContainer}>
            <div className={styles.marginBlock}>
              <div>
                <label>Email:</label>
              </div>
              <div>
                <input name='email' type='text' value={form.email} onChange={changeHandler} />
              </div>
            </div>
            <div className={styles.marginBlock}>
              <div>
                <label>Password:</label>
              </div>
              <div>
                <input name='password' type='password' value={form.password} onChange={changeHandler} />
              </div>
            </div>
            <div className={styles.marginBlock}>
              <button className={styles.loginButton} onClick={handleSubmit} type='submit'>
                Log in
              </button>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export const fragmentLoginBlock = graphql`
  fragment LoginBlock on ContentfulLoginBlock {
    id
  }
`;

export default LoginBlock;
