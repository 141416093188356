import React, { Component } from 'react'
import get from 'lodash/get'
// import styles from './card-link-block.module.less';
import { graphql } from 'gatsby'
import LinkWrapper from 'components/block-elements/link-wrapper/link-wrapper';

export default class CardLinkBlock extends Component {
  constructor(props) {
    super(props);
    this.link = get(props, 'link.slug');
    this.label = get(props, 'label');
    this.backgroundUrl = get(props, 'backgroundImage.file.url');
  }

  render() {
    return (
      <LinkWrapper link={this.link}>
        <div></div>
      </LinkWrapper>
    )
  }
}

export const fragmentCardLinkBlock = graphql`
    fragment CardLinkBlock on ContentfulCardLinkBlock {
        id
        backgroundImage {
            file {
                url
            }
        }
        link {
            slug
        }
        label
    }
`
