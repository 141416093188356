class MatchEventsConfigurationStore {
  constructor() {
    this.eventsList = [];
    this.exludedEvents = [];
  }

  init(list){
    if (this.eventsList.length < 1) {
      this.eventsList = list && list instanceof Array && list.length > 0 ? list : [];
      list.forEach(item => {
        if (item.hideInEventsList){
          this.exludedEvents.push(item.type);
        }
      });
    }
  }

  isValidPeriodName(obj,item){
    return obj.periodName === null || item.periodNames === "None" ? false : true;
  }  

  getEventItem(obj) {
    // debugger
    if(obj.goalScorerMethod !== 'own_goal'){
      return obj.periodName === null ?
      this.eventsList.find(item => item.eventType && item.eventType === obj.type) :  
      this.eventsList.find(item => item.eventType && item.eventType === obj.type && item.periodNames === obj.periodName); 
    } else {
      return this.eventsList.find(item => item.type === 'score_change, own goal');
    }
  }
  

  getExcludedEvents() {
    return this.exludedEvents;
  }
}

const helper = new MatchEventsConfigurationStore();
module.exports = helper;
