import React, { StrictMode } from 'react';
import Helmet from 'react-helmet';
import { useAuth } from 'components/hooks/auth.hook.js';
import { AuthContext } from 'components/helpers/auth-context.js';
import get from 'lodash/get';
import Layout from 'components/layout.js';
import SiteBlocks from 'components/blocks/site-blocks/site-blocks.js';
import MainNavigation from 'components/navigations/main-navigation/main-navigation';
import FooterNavigation from 'components/navigations/footer-navigation/footer-navigation';
import ThemeHelper from 'components/helpers/theme-helper';
import FaceBookHelper from 'components/helpers/facebook-helper.js';
import { GlobalContextConsumer } from 'components/stores/global-context';
import ScrollToTopButton from '../components/blocks/scroll-to-top-button/scroll-to-top-button.js';
import Breadcrumb from 'components/block-elements/breadcrumb/breadcrumb';
import SiteConfigurationStore from 'components/stores/site-configuration-store';
import SubNav from 'components/navigations/sub-nav/sub-nav';
import 'components/page.less';
// import banner from 'static/300x50.png';
// import banner2 from 'static/300x100.png';

const Page = (props) => {
  const contentPage = get(props, 'data.contentfulContentPage');
  const siteConfig = SiteConfigurationStore.getConfiguration();
  const navigation = get(siteConfig, 'defaultMainNavigation');
  const subNav = contentPage.subNav ? contentPage.subNav : get(siteConfig, 'defaultSubNav');
  const hasSubNav = subNav && subNav.subNavItems instanceof Array && subNav.subNavItems.length > 0;
  const navigationFooter = get(siteConfig, 'defaultFooter');
  const blocks = contentPage.blocks instanceof Array ? contentPage.blocks : [];
  const pageTitle = get(contentPage, 'title', '');
  const pageDescription = get(contentPage, 'description', '');
  const pageKeywords = get(contentPage, 'keywords', '');
  const excludePageFromExternalSearch = get(contentPage, 'excludeFromExternalSearch', false);
  const showScrollToTopButton = get(contentPage, 'showScrollToTopButton');
  const breadcrumb = getBreadcrumb();
  const isStartPage = contentPage.slug === '/';
  const isMatch = contentPage.matchId ? true : false;
  const isMcmNewsPage = contentPage.isMcmNewsPage ? true : false;

  const additionalPropsForBlocks = getAdditionalPropsForBlocks();
  const { token, login, logout, userId, ready, user, isAuthorize } = useAuth();
  const isAunthenticated = !!token;

  function getBreadcrumb() {
    const breadcrumb = [];

    breadcrumb.reverse();

    breadcrumb.push({
      id: contentPage.id,
      title: contentPage.title,
      slug: contentPage.slug,
    });

    return breadcrumb;
  }

  // componentDidMount()
  // {
  //   this.imageMarginsWatcher();
  //   window.addEventListener('resize', () =>
  //   {
  //     this.imageMarginsWatcher();
  //   })
  // }

  // imageMarginsWatcher()
  // {
  //   let width = document.body.clientWidth;

  //   // document.getElementById('advertising-link').style.maxHeight = `${document.getElementById('advertising-image').offsetHeight}px`;

  //   if(width > 450)
  //   {
  //     document.getElementById('advertising-box').style.marginTop = `-${(document.getElementById('advertising-box').offsetHeight)}px`
  //   }
  //   else
  //   {
  //     document.getElementById('advertising-box').style.marginTop = "0px";
  //   }
  // }

  function getBlockPropMap() {
    return [
      {
        typename: 'ContentfulLiveScoreBlock',
        props: ['allContentfulMatch'],
      },
      {
        typename: 'ContentfulBetSuggestionBlock',
        props: ['allContentfulMatch'],
      },
    ];
  }

  function getAdditionalPropsForBlocks() {
    if (!(blocks instanceof Array && blocks.length)) {
      return null;
    }

    const blockPropMap = getBlockPropMap();

    const blockPropMapForPage = blockPropMap.filter((blockProp) =>
      blocks.find((block) => block.__typename === blockProp.typename)
    );

    const blockPropMapWithData = blockPropMapForPage.map((blockProp) => {
      return {
        typename: blockProp.typename,
        props: getPropsForBlock(blockProp.props),
      };
    });

    return blockPropMapWithData;
  }

  function getPropsForBlock(props) {
    if (!(props instanceof Array && props.length)) {
      return null;
    }

    const propsWithData = props.reduce((obj, propName) => {
      obj[propName] = getPagePropsByName(propName);
      return obj;
    }, {});

    return propsWithData;
  }

  function getPagePropsByName(propName) {
    if (props.data && props.data[propName]) {
      return props.data[propName];
    }
    return null;
  }

  const facebookImage = FaceBookHelper.getFacebookImageByCurrentBlocks(blocks);
  const facebookLocale = FaceBookHelper.getFacebookLocale();
  const facebookUrl = FaceBookHelper.getFaceBookUrl(props.location.pathname);
  // TODO: Index pages when ready for real release
  const metaRobotsContent = excludePageFromExternalSearch ? 'noindex,follow' : 'noindex,follow';

  return (
    <AuthContext.Provider value={{ token, login, logout, userId, isAunthenticated, user, isAuthorize, ready }}>
      <div className={'theme-' + ThemeHelper.getTheme()}>
        <h1 className='visuallyHidden'>{pageTitle}</h1>
        <Layout className={hasSubNav ? 'has-sub-nav' : null}>
          <Helmet
            title={pageTitle + ' | MatchCenterMedia'}
            meta={[
              { name: 'description', content: pageDescription },
              { name: 'keywords', content: pageKeywords },
              { name: 'theme-color', content: '#000000' },
              { name: 'robots', content: metaRobotsContent },
              { property: 'og:url', content: facebookUrl },
              { property: 'og:title', content: pageTitle },
              {
                property: 'og:description',
                content: pageDescription,
              },
              { property: 'og:type', content: 'website' },
              { property: 'og:image', content: facebookImage },
              { property: 'og:locale', content: facebookLocale },
            ]}
            link={[
              { rel: 'manifest', href: '/manifest.webmanifest' },
              {
                rel: 'icon',
                type: 'image/x-icon',
                href: '/favicon.ico',
              },
              {
                rel: 'icon',
                sizes: '192x192',
                href: '/icons/icon-192x192.png',
              },
            ]}
          />
          {navigation && <MainNavigation data={navigation} siteConfig={siteConfig} />}
          {!isStartPage && breadcrumb instanceof Array && breadcrumb.length && (
            <Breadcrumb pages={breadcrumb} isMatch={isMatch} isMcmNewsPage={isMcmNewsPage} />
          )}
          {hasSubNav && <SubNav {...subNav} />}
          <GlobalContextConsumer>
            {(globalContext) => (
              <main id={'Main'} className={['main', globalContext.isMainVisible ? '' : 'display-none'].join(' ')}>
                <ScrollToTopButton data={contentPage.scrollToTopButton} showScrollToTopButton={showScrollToTopButton} />
                {blocks instanceof Array && blocks.length ? (
                  <SiteBlocks blocks={blocks} additionalPropsForBlocks={additionalPropsForBlocks} />
                ) : null}
                <StrictMode>
                  {props.children}
                </StrictMode>
              </main>
            )}
          </GlobalContextConsumer>
          {navigationFooter && <FooterNavigation data={navigationFooter} siteSettings={siteConfig} />}
          {/* <div id='advertising-box' className='advertising'>
            <img onClick={() => window.open('http://www.example.com?ReportID=1', '_blank')} id='advertising-image' className='advertising-image' src={banner2}></img>
          </div> */}
        </Layout>
      </div>
    </AuthContext.Provider>
  );
};

export default Page;
