import axios from 'components/helpers/axios';

const url = 'api/rss';

class RssService {
  GetRssNews(name) {
    return axios.get(`${url}?name=${name}`);
  }
}

const service = new RssService();
export default service;
