import React, { Component } from 'react';
import './select-dropdown.module.less';

export default class SelectDropdown extends Component {

    constructor(props){
        super(props);
        this.ref_select = React.createRef();
    }

    componentDidMount(){
        if(!this.isRefSelectIndexCorrect()){
            this.ref_select.current.selectedIndex = this.getSelectedOptionIndex();
        }
    }

    isRefSelectIndexCorrect(){
        return this.ref_select.current.selectedIndex === this.getSelectedOptionIndex();
    }

    getSelectedOptionIndex(){
        if(!(this.props.selectedListItem instanceof Object)){
            return 0;
        }
        
        let foundIndex = this.props.list.findIndex(option => option.value === this.props.selectedListItem.value && option.label === this.props.selectedListItem.label);
        return foundIndex >= 0 ? foundIndex : 0;
    }

    getId() {
        this.id = this.props.id ? this.props.id : this.id ? this.id : "select_" + Math.floor((Math.random() * 1000000) + 1);
        return this.id;
    }

    onChange(e){
        if(typeof this.props.onChange === 'function') {
            this.props.onChange(e,this.props.list[e.target.selectedIndex]);
        }
    }

    render() {
        const SelectBtn = this.props.selectBtnHtml ? this.props.selectBtnHtml : "";

        return <div className={'ui-dropdown-select__wrapper'}>
                    <div className={'ui-dropdown-select__btn'}>{ SelectBtn ? <SelectBtn {...this.props.selectedListItem } /> : "" }</div>
                    {/* eslint-disable-next-line */}
                    <select ref={this.ref_select}
                        id={this.getId()}
                        onChange={(e) => this.onChange(e)} 
                        className={'ui-dropdown-select'}>{ 
                            this.props.list.map((li,i) => <option key={this.getId() + "_" + i} value={li.value}>{li.label}</option>)
                        }
                    </select>
               </div>
    }
}