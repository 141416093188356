import EventAttributeStore from './ui-bkg-image-event-attribute-store.js';

class UiBkgImageEventAggregator {

    constructor(){
        this.isInitialized = false;
        this.events = {};
        this.hOP = this.events.hasOwnProperty;
        this.scrollableContainer = null;
    }

    subscribe(eventName, callbackFunction) {
        // Create the eventName's object if not yet created
        if(!this.hOP.call(this.events, eventName)){
            this.events[eventName] = [];
        } 
  
        // Add the listener to queue
        var index = this.events[eventName].push(callbackFunction) -1;
  
        // Provide handle back for removal of eventName
        return {
          remove: () => {
            delete this.events[eventName][index];
          }
        };
    }

    publish(eventName, info) {
        // If the eventName doesn't exist, or there's no listeners in queue, just leave
        if(!this.hOP.call(this.events, eventName)) return;
  
        // Cycle through events queue, fire!
        this.events[eventName].forEach(function(item) {
                item(info !== undefined ? info : {});
        });
    }

    initListeners(scrollableContainer){
        if(typeof window == 'undefined') {
            return;
        }

        if(scrollableContainer) {
            this.initScrollableContainerListeners(scrollableContainer);
        }

        if(!this.isInitialized){
            this.isInitialized = true;

            window.addEventListener('resize', (e) => {
                EventAttributeStore.windowWidth = (window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth);
                EventAttributeStore.windowHeight = (window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight);
                this.publish("onResizeWindowWidth",EventAttributeStore.windowWidth);
                this.publish("onResizeWindowHeight",EventAttributeStore.windowHeight);
            });
    
            window.addEventListener("scroll", () => {
                EventAttributeStore.scrollY = (window.scrollY || window.pageYOffset || document.documentElement.scrollTop);
                this.publish("onScroll", EventAttributeStore.scrollY)
            });

            window.addEventListener('touchmove', () => {
                let scrollTop = (window.scrollY || window.pageYOffset || document.documentElement.scrollTop);
                
                if (scrollTop !== EventAttributeStore.scrollY) {
                    EventAttributeStore.scrollY = scrollTop;
                    this.publish("onScroll", EventAttributeStore.scrollY);
                }
            });
        }
    }

    initScrollableContainerListeners(container) {
        let willAddEventListeners = this.scrollableContainer ? false : true;

        if(!this.scrollableContainer || (this.scrollableContainer && !this.scrollableContainer.isSameNode(container))) {
            this.scrollableContainer = container;
            willAddEventListeners = true;
        }

        if(willAddEventListeners) {
            this.scrollableContainer.addEventListener("scroll", () => {
                EventAttributeStore.overflowScrollY = this.scrollableContainer.scrollTop;
                this.publish("overflowOnScroll", EventAttributeStore.overflowScrollY);
            });

            this.scrollableContainer.addEventListener("touchmove", () => {
                if(this.scrollableContainer.scrollTop !== EventAttributeStore.overflowScrollY) {
                    EventAttributeStore.overflowScrollY = this.scrollableContainer.scrollTop;
                    this.publish("overflowOnScroll", EventAttributeStore.overflowScrollY);
                }
            })
        }
    }
}

const singleton = new UiBkgImageEventAggregator();
export default singleton;