import styles from './standings-table-soccer.module.less';
import React, { Component } from 'react';
import get from 'lodash/get';
import TranslationStore from 'components/stores/translation-store';

export default class StandingsTableSoccer extends Component {
  constructor(props) {
    super(props);
    this.playedMatchesShort = TranslationStore.getTranslation("standing_table_soccer_abrv_played_matches");
    this.winsShort = TranslationStore.getTranslation("standing_table_soccer_abrv_wins");
    this.drawsShort = TranslationStore.getTranslation("standing_table_soccer_abrv_draws");
    this.lossesShort = TranslationStore.getTranslation("standing_table_soccer_abrv_losses");
    this.plusMinusShort = TranslationStore.getTranslation("standing_table_soccer_abrv_plus_minus");
    this.pointsShort = TranslationStore.getTranslation("standing_table_soccer_abrv_points");

    this.outcomeRows = get(props, 'outcomeRows');
    // this.highlighted = get(props, 'highlighted');
    // this.groupNameForMatch = this.getGroupNameFromHighlighted(this.highlighted, this.props.standings);
    this.groups = this.props.standings.find(team => team.groupName) ? this.groupQualifyGroups() : null;
  }

  // getGroupNameFromHighlighted(highlighted, standings) {
  //   const firstTeamInHighlighted = highlighted instanceof Array && highlighted.length > 0 ? highlighted[0] : null;

  //   if (firstTeamInHighlighted) {
  //     const team = standings.find(team => team.teamId === firstTeamInHighlighted);
  //     return team ? team.groupName : null;
  //   }

  //   return null;
  // }

  groupQualifyGroups() {
    const groups = this.props.standings.reduce((acc, currentStanding) => {
      const groupName = currentStanding.groupName;
      if (acc[groupName] instanceof Array) {
        acc[groupName].push(currentStanding);
      } else {
        acc[groupName] = [currentStanding];
      }
      return acc;
    }, {});

    return groups;
  }

  renderTableForGroups(groups, standings) {
    const standingsPerGroup = [];
    for (let groupName in groups) {
      const group = {
        groupName: groupName,
        teamsForGroup: groups[groupName],
      };
      standingsPerGroup.push(group);
    }

    return standingsPerGroup.map(group => {
      return (
        <div key={group.groupName}>
          {/* <h4 className={styles.groupName}>Grupp {group.groupName}</h4> */}
          {this.renderStandings(group.teamsForGroup)}
        </div>
      );
    });
  }

  renderStandings(standings) {
    let data = this.props?.colors?.filter(el => 
      (el?.leagueName?.toLowerCase() == this.props?.tournamentId?.toLowerCase()));

      standings.forEach((el, index) =>
        data?.forEach(dat => {
          return ((index + 1) >= dat?.teamsStart && (index + 1) <= dat?.teamsEnd) ? el.background = dat?.color : null
        })
      )

    return (
      <div className={[styles.wrapper].join(' ')}>
        <table className={[styles.standingsList__table].join(' ')}>
          <thead>
            <tr className={styles.standingsList__tableTitles}>
              {/* eslint-disable-next-line */}
              <th>#</th>
              {/* eslint-disable-next-line */}
              <th className={styles.standingsList__teamLogo} />
              {/* eslint-disable-next-line */}
              <th className={styles.standingsList__teamNameTitle}>Klubb</th>
              {this.playedMatchesShort && <th>{this.playedMatchesShort}</th>}
              {this.winsShort && <th>{this.winsShort}</th>}
              {this.drawsShort && <th>{this.drawsShort}</th>}
              {this.lossesShort && <th>{this.lossesShort}</th>}
              {this.plusMinusShort && <th>{this.plusMinusShort}</th>}
              {this.pointsShort && <th>{this.pointsShort}</th>}
            </tr>
          </thead>
          <tbody className={styles.standingsList__body}>
            {standings.map((team, index) => {
              return (
                <tr
                  style={this.props.isTotalShow ? {background: `${team.background}`} : null}
                  className={[styles.standingsList__row, 
                    team.currentOutcomeClass ? team.currentOutcomeClass : '', 
                    (this.props.teamsInLigue && this.props.teamsInLigue >= index+1) ? '' : null].join(
                    ' '
                  )}
                  key={index}>
                  <td className={[styles.standingsList__center, styles.standingsList__rank].join(' ')}>{index+1}</td>
                  <td className={styles.standingsList__logo}>
                  { team.teamId ? <img src={team.teamLogoImage} alt={`${team.teamName} logo`} className={styles.teamLogo} /> : "" }
                  </td>
                  <td
                    className={[
                      styles.standingsList__teamName,
                      // this.highlighted.indexOf(team.teamId) !== -1 ? styles.standingsList__highlighted : '',
                    ].join(' ')}>
                    {(team.teamId == this.props.homeTeamId || team.teamId == this.props.awayTeamId) ? <span style={{fontFamily: 'docu-bold'}}>{team.teamName}</span> : team.teamName}
                  </td>
                  <td className={styles.standingsList__center}>{team.total}</td>
                  <td className={styles.standingsList__center}>{team.wins}</td>
                  <td className={styles.standingsList__center}>{team.draws}</td>
                  <td className={styles.standingsList__center}>{team.loses}</td>
                  <td className={styles.standingsList__center}>{team.goalsDifference}</td>
                  <td className={[styles.standingsList__center, styles.standingsList__points].join(' ')}>
                    {team.points}
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
        {this.outcomeRows}
        {this.outcomeRows && this.outcomeRows.length > 0 && (
          <div className={styles.outcomeRows}>
            {this.outcomeRows.map((item, index) => (
              <div key={index} className={styles.outcomeItem}>
                <div className={[styles.dot, item.currentOutcomeClass].join(' ')} />
                <span className={styles.outcomeText}>{item.currentOutcome}</span>
              </div>
            ))}
          </div>
        )}

              {data?.find(el => el.teamsStart == 0 && el.teamsEnd == 0) && <div style={{display: 'flex', justifyContent: 'center', fontFamily: 'docu-regular', fontSize: '0.75rem', textAlign: 'center'}}>
                <span dangerouslySetInnerHTML={{__html: data?.find(el => el.teamsStart == 0 && el.teamsEnd == 0)?.tournamentName}}></span>
              </div>}
              {this.props.isTotalShow ? <div style={{display: 'flex', marginLeft: '10px', marginTop: '20px', marginBottom: '20px', justifyContent: 'center'}}>
                  {data.map(el => 
                  <>
                    {el.teamsStart != 0 && el.teamsEnd != 0 && <div style={{display: 'flex', alignItems: 'center', marginRight: '10px'}}>
                      <div style={{width: '10px', marginRight: '5px', height: '10px', background: `${el.color}`, minWidth: '10px'}}></div>
                      <span style={{fontFamily: 'docu-regular', fontSize: '0.75rem'}}>{el.tournamentName}</span>
                    </div>}
                  </>
                    )}
              </div> : null}

      </div>
    );
  }

  render() {
    const filteredStandings = this.groupNameForMatch
      ? this.props.standings.filter(team => team.groupName === this.groupNameForMatch)
      : this.props.standings;

    if (this.groupNameForMatch) {
      return this.renderStandings(filteredStandings);
    } else if (this.groups) {
      return this.renderTableForGroups(this.groups);
    } else {
      return this.renderStandings(filteredStandings);
    }
  }
}
