import styles from './standings-scoring-league-soccer.module.less';
import React, { Component } from 'react';
import TranslationStore from 'components/stores/translation-store';
import UiBtn from 'components/block-elements/ui-btn/ui-btn';
import plusIcon from 'static/plus.png'

export default class StandingsScoringLeagueSoccer extends Component {
  constructor(props) {
    super(props);
    this.standings = this.props.standings instanceof Array ? this.props.standings : [];

    this.playerLabel = TranslationStore.getTranslation("standings_scoring_league_soccer_player");
    this.teamLabel = TranslationStore.getTranslation("standings_scoring_league_soccer_team");
    this.matchesLabel = TranslationStore.getTranslation("standings_scoring_league_soccer_matches_played");
    this.goalsLabel = TranslationStore.getTranslation("standings_scoring_league_soccer_goals");
    this.showMoreLabel = TranslationStore.getTranslation("standings_scoring_league_soccer_show_more");

    this.state = {
      itemsToShow: 10,
    };
  }

  renderTableHead = () => (
    <thead>
      <tr className={styles.standingsList__tableTitles}>
        {/* eslint-disable-next-line */}
        <th className={styles.standingsList__rankHeader}>#</th>
        {this.playerLabel && <th className={styles.standingsList__left}>{this.playerLabel}</th>}
        {this.teamLabel && <th className={styles.standingsList__left}>{this.teamLabel}</th>}
        
        {this.goalsLabel && <th className={styles.standingsList__left}>{this.goalsLabel}</th>}
      </tr>
    </thead>
  );

  render() {
    return (
      <>
        <div className={[styles.wrapper].join(' ')}>
          {this.standings && this.standings.length > 0 ? <table className={styles.standingsList__table}>
            {this.renderTableHead()}
            <tbody className={styles.standingsList__body}>
              {this.standings.slice(0, this.state.itemsToShow).map((player, index) => {
                return (
                  <tr
                    className={[styles.standingsList__row, index % 2 !== 0 ? styles.standingsList__rowUneven : ''].join(
                      ' '
                    )}
                    key={player.id}>
                    <td className={[styles.standingsList__center, styles.standingsList__rank].join(' ')}>
                      {player.rank}
                    </td>
                    <td className={[styles.standingsList__left, styles.standingsList__name].join(' ')}>{player.playerName}</td>
                    <td className={[styles.standingsList__left, styles.standingsList__team].join(' ')}>{player.competitorName}</td>
                    
                    <td className={[styles.standingsList__left, styles.standingsList__points].join(' ')}>
                      {player.goals}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table> : <div style={{textAlign: 'center'}}>
            <h3>Ingen information tillgänglig i denna liga</h3>
            </div>}
          {/* {this.standings.length > this.state.itemsToShow && (
            <div className={styles.align__center}>
              <UiBtn
                style={{ width: '3rem' ,fontSize: '2.5rem', fontFamily: 'icomoon', lineHeight: '2.5rem', padding: '0'}}
                onClick={() => this.setState({ itemsToShow: this.state.itemsToShow + 10 })}
                className={'ui-btn--medium ui-btn--primary waves-effect'}
                label="+"
                // label={this.showMoreLabel}
              />
              <div style={{marginTop: '5px', fontSize: '0.75rem', color: 'grey'}}><span>{this.showMoreLabel}</span></div>
            </div>
          )} */}

        {this.standings.length > this.state.itemsToShow && (
          <div style={{marginTop: '1rem', marginBottom: '1rem'}}>
            <div style={{display: 'flex', justifyContent: 'center'}}>
              <div 
                className={'waves-effect'} 
                onClick={() => this.setState({ itemsToShow: this.state.itemsToShow + 10 })}
                style={{minWidth: '3rem', minHeight: '3rem', background: '#00c197', borderRadius: '0.375rem', justifyContent: 'center', alignSelf: 'center'}}>
                  <div style={{display: 'flex', justifyContent: 'center', fontSize: '2.5rem', height: '3rem', lineHeight: 'initial', alignItems: 'center', color: 'white'}}>
                    <img width={35} height={35} src={plusIcon}></img>
                  </div>
                </div>
            </div>
            <div style={{display: 'flex', justifyContent: 'center'}}>
              <div style={{marginTop: '5px', fontSize: '0.75rem', color: 'grey'}}><span>{this.showMoreLabel}</span></div>
            </div>
          </div>)}
        </div>
        <div className={styles.errorMessage}>
          {this.standings.length === 0 && (
            <span className={styles.errorMessageText}>Fanns ingen data för denna match/liga.</span>
          )}
        </div>
      </>
    );
  }
}
