import React, { Component } from 'react';
import get from 'lodash/get';
import { graphql } from 'gatsby';
import styles from './bet-suggestion-block.module.less';
import MatchListItemBet from 'components/block-elements/match-list-item-bet-copy/match-list-item-bet-copy';
import LinkWrapper from 'components/block-elements/link-wrapper/link-wrapper';
import OddsService from 'services/odds-service';
import Loader from 'components/block-elements/loader-css/loader-css';

export default class BetSuggestionBlock extends Component {
  constructor(props) {
    super(props);
    this.state = {
      matches: [],
      loading: true,
      filter: {
        searchText: '',
        page: 0,
        isShowOnlyActive: true
      }
    }
  }

  async componentDidMount() {
    await OddsService.getAllBets(this.state.filter)
      .then(res => {
        this.setState({matches: res.data.data})
      }).catch(err => {
      })

      this.setState({loading: false});
  }

  render() {
    return (
      <section className={[styles.betSuggestionWrapper].join(" ")}>
        <div className={'block__padded block__max-width-narrow'}>
        { !this.state.loading ? <div>
          {
            this.state.matches?.length > 0 &&
            this.state.matches.map(match => {
              return <MatchListItemBet key={match.matchId} {...match} />;
            })
          }
        </div> : <Loader/> }
        </div>
      </section>
    );
  }
}

export const fragmentBetSuggestionBlock = graphql`
    fragment BetSuggestionBlock on ContentfulBetSuggestionBlock {
        id
        title
        sports {
            sportId
            icon {
                file {
                    url
                }
            }
        }
    }
`;

