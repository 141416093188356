import React, { Component } from 'react';
import styles from './tournament-standings-list.module.less';
import get from 'lodash/get';
import StandingsTableSoccer from 'components/block-elements/standings-table-soccer/standings-table-soccer';
import StandingsTableHockey from 'components/block-elements/standings-table-hockey/standings-table-hockey';

export default class TournamentStandingsList extends Component {
  constructor(props) {
    super(props);
    this.tournamentName = get(props, 'tournamentName');
    this.sportsId = get(props, 'sportsId');
    this.highlighted = get(props, 'highlighted');
    this.tournamentId = get(props, 'tournamentId');
    this.colors = get(props, 'colors');

    this.sportsTypes = {
      'e:sport:1': {
        component: StandingsTableSoccer,
      },
      'e:sport:5': {
        component: StandingsTableHockey,
      }
    }
  }

  renderTableForType() {
    const ComponentType = this.sportsTypes[this.sportsId].component;

    if (typeof ComponentType === 'undefined') {
      return null;
    }

    return (
      this.props.standingGroups.map((group, index) => (
      <ComponentType
        key={index}
        highlighted={this.highlighted}
        sportId={this.sportsId}
        colors={this.colors}
        tournamentId={this.tournamentId}
        outcomeRows={group.outcomeRows}
        standings={group.standingRows}
        isMatchPage={this.props.isMatchPage}
      />))
    );
  }

  renderErrorMessage() {
    return <div className={styles.errorMessage}>{this.errorMessage}</div>;
  }

  render() {
    return (
      <div className={styles.standingsList}>
        {
          this.props.standingGroups.length ? this.renderTableForType() : ""
        }
        {
          JSON.stringify(this.props)
        }
      </div>
    );
  }
}
