import styles from './standings-scoring-league-hockey.module.less';
import React, { Component } from 'react';
import TranslationStore from 'components/stores/translation-store';
import UiBtn from 'components/block-elements/ui-btn/ui-btn';

export default class StandingsScoringLeagueHockey extends Component {
  constructor(props) {
    super(props);
    this.standings = this.props.standings instanceof Array ? this.props.standings : [];
    this.playerLabel = TranslationStore.getTranslation("standings_scoring_league_hockey_player");
    this.teamLabel = TranslationStore.getTranslation("standings_scoring_league_hockey_team");
    this.goalsLabel = TranslationStore.getTranslation("standings_scoring_league_hockey_goals");
    this.pointsLabel = TranslationStore.getTranslation("standings_scoring_league_hockey_points");
    this.matchesLabel = TranslationStore.getTranslation("standings_scoring_league_hockey_matches_played");
    this.assistLabel = TranslationStore.getTranslation("standings_scoring_league_hockey_assist");
    this.showMoreLabel = TranslationStore.getTranslation("standings_scoring_league_hockey_show_more");

    this.state = {
      itemsToShow: 10,
    };
  }

  render() {
    let players = this.standings.slice(0, this.state.itemsToShow);
    
    let currentPlace = 1;
    players.sort((a, b) => {
      if ((a.goals + a.assists) === (b.goals + b.assists)) {
        if (a.goals === b.goals) {
          return 0;
        }

        return a.goals > b.goals ? -1 : 1;
      }

      if ((a.goals + a.assists) > (b.goals + b.assists)) return -1;
      if ((a.goals + a.assists) < (b.goals + b.assists)) return 1;
      return 0;
    });


    for (let i = 0, iMax = players.length; i < iMax; i++) {
      let playersAssignedSamePosition = 1;
      players[i].rankGoals = currentPlace;



      while ((players.length - 1) > i &&
        ((players[(i + 1)].assists + players[(i + 1)].goals) === (players[i].goals + players[i].assists)) &&
        players[(i + 1)].goals === players[i].goals
      ) {

        players[(i + 1)].rankGoals = currentPlace;
        playersAssignedSamePosition++;

        i++;

      }

      currentPlace = currentPlace + playersAssignedSamePosition;
    }

    return (
      <>
        <div className={[styles.wrapper].join(' ')}>
          <table className={styles.standingsList__table}>
            <thead>
              <tr className={styles.standingsList__tableTitles}>
                {/* eslint-disable-next-line */}
                <th className={styles.standingsList__rankHeader} />
                {this.playerLabel && <th className={styles.standingsList__left}>{this.playerLabel}</th>}
                {this.teamLabel && <th className={styles.standingsList__left}>{this.teamLabel}</th>}
                {this.matchesLabel && <th className={styles.standingsList__left}>{this.matchesLabel}</th>}
                {this.goalsLabel && <th className={styles.standingsList__left}>{this.goalsLabel}</th>}
                {this.assistLabel && <th className={styles.standingsList__left}>{this.assistLabel}</th>}
                {this.pointsLabel && <th className={styles.standingsList__left}>{this.pointsLabel}</th>}

              </tr>
            </thead>
            <tbody className={styles.standingsList__body}>
              {

                players.map((player, index) => {
                  return (
                    <tr
                      className={[styles.standingsList__row, index % 2 !== 0 ? styles.standingsList__rowUneven : ''].join(
                        ' '
                      )}
                      key={index}>
                      <td className={[styles.standingsList__center, styles.standingsList__rank].join(' ')}>
                        {index + 1}
                      </td>
                      <td className={styles.standingsList__left}>{player.playerName}</td>
                      <td className={[styles.standingsList__left, styles.standingsList__team].join(' ')}>{player.competitorName}</td>
                      <td className={styles.standingsList__left}>{player.matchesPlayed}</td>
                      <td className={[styles.standingsList__left, styles.standingsList__goals].join(' ')}>{player.goals}</td>
                      <td className={styles.standingsList__left}>{player.assists}</td>
                      <td className={[styles.standingsList__left, styles.standingsList__points].join(' ')}>{player.points}</td>
                    </tr>
                  );
                })}
            </tbody>
          </table>
          {this.standings.length > this.state.itemsToShow && (
            <div className={styles.align__center}>
              <UiBtn
                style={{ width: '3rem' ,fontSize: '2.5rem', fontFamily: 'icomoon', lineHeight: '2.5rem', padding: '0'}}
                onClick={() => this.setState({ itemsToShow: this.state.itemsToShow + 10 })}
                className={'ui-btn--medium ui-btn--primary waves-effect'}
                label="+"
              />
            </div>
          )}
        </div>
        <div className={styles.errorMessage}>
          {this.standings.length === 0 && (
            <span className={styles.errorMessageText}>Fanns ingen data för denna match/liga.</span>
          )}
        </div>
      </>
    );
  }
}
