class ImageTypeSupportHelper {

    constructor() {
        this.isSupportingWebp = null;
        this.resultCalculated = false;
        this.calculatingResultPromise = null;

        if(typeof window !== 'undefined'){
            this.webPTestedPromise = this.testWebP();
        }
    }

    testWebP() {

        if(this.resultCalculated) {
            return Promise.resolve();
        }

        if(this.calculatingResultPromise) {
            return this.calculatingResultPromise;
        }
        
        let webP = new Image();
        webP.src = 'data:image/webp;base64,UklGRjoAAABXRUJQVlA4IC4AAACyAgCdASoCAAIALmk0mk0iIiIiIgBoSygABc6WWgAA/veff/0PP8bA//LwYAAA';
        this.calculatingResultPromise = new Promise((resolve, reject) => {
            webP.onload = webP.onerror = () => { 
                this.resultCalculated = true;
                this.isSupportingWebp = webP.height === 2;
                resolve();
            };
        })

        return this.calculatingResultPromise;
    }

    getWebPResult() {
        if(typeof window !== 'undefined'){
            return this.webPTestedPromise.then( () => this.isSupportingWebp);
        }

        return null;
    }


}

const singleton = new ImageTypeSupportHelper();
export default singleton;