import React, { Component } from 'react';
import { graphql, Link } from 'gatsby';
import get from 'lodash/get';
import styles from './news-block.module.less';
import dateHelper from '../../helpers/date-helper';
import rssHelper from '../../helpers/rss-helper';
import UiSelect from 'components/block-elements/ui-select/ui-select';
import RssService from 'services/rss-service';
import LoaderCSS from '../../block-elements/loader-css/loader-css';

class NewsBlock extends Component {
  constructor(props) {
    super(props);
    this.news = get(props, 'newsSource');
    this.mcmNewsList = get(props, 'mcmNewsList.mcmNewsList');
    this.selectOptions = this.formatToSelect();
    this.loadMore = this.loadMore.bind(this);

    this.state = {
      fotbollskanalenFeed: [],
      fotbolltransfersAllsvenskan: [],
      fotbolltransfersSuperettan: [],
      fotbolltransfersRestOfEurope: [],
      fotbollDirekt: [],
      hockeySverige: [],
      matchcentermedia: [],
      visible: 20,
      loading: true,
      league: null,
    };
    
  }

  componentDidMount() {
    this.fetchDataFromAllRSSFeed();
  }

  fetchDataFromAllRSSFeed = newsSource => {
    const fk = "fk"
    const ftAllsvenskan = "FtAllsvenskan"
    const ftSuperettan = "FtSuperettan"
    const ftRestOfEurope = "FtRestOfEurope"
    const fotbollDirekt = "FotbollDirekt"
    const hockeySverige = "HockeySverige"

    Promise.all([
      RssService.GetRssNews(fk),
      RssService.GetRssNews(ftAllsvenskan),
      RssService.GetRssNews(ftSuperettan),
      RssService.GetRssNews(ftRestOfEurope),
      RssService.GetRssNews(fotbollDirekt),
      RssService.GetRssNews(hockeySverige),
    ])
      .then(res => { 
        res.forEach((items,i) => { items.data.forEach((item,j) => {item.id = "id_" + i + "_" + j })})

        this.setState({
          fotbollskanalenFeed: res[0].data.map(item => ({ ...item, source: 'Fotbollskanalen' })),
          fotbolltransfersAllsvenskan: res[1].data.map(item => ({ ...item, source: 'Fotbolltransfers' })),
          fotbolltransfersSuperettan: res[2].data.map(item => ({ ...item, source: 'Fotbolltransfers' })),
          fotbolltransfersRestOfEurope: res[3].data.map(item => ({ ...item, source: 'Fotbolltransfers' })),
          fotbollDirekt: res[4].data.map(item => ({ ...item, source: 'FotbollDirekt' })),
          hockeySverige: res[5].data.map(item => ({ ...item, source: 'Hockeysverige' })),
          matchcentermedia: this.mcmNewsList ? this.mcmNewsList.map(item => ({ ...item, source: 'Matchcentermedia' })) : [],
          visible: 20,
          loading: false,
        });
      })
      .catch(this.setState({ loading: true }));
  };

  formatToSelect() {
    return [
      {
        key: 'all',
        value: 'all',
        text: 'Välj nyheter',
      },
      {
        key: 'Allsvenskan',
        value: 'fotbolltransfersAllsvenskan',
        text: 'Allsvenskan',
      },
      {
        key: 'Superettan',
        value: 'fotbolltransfersSuperettan',
        text: 'Superettan',
      },
      {
        key: 'Övriga Europa',
        value: 'fotbolltransfersRestOfEurope',
        text: 'Övriga Europa',
      },
    ];
  }

  loadMore() {
    this.setState(prev => {
      return { visible: prev.visible + 20 };//changes
    });
  }

  combineAndSortFeeds() {
    
    let combinedNews = [
      ...this.state.fotbollskanalenFeed,
      ...this.state.fotbolltransfersAllsvenskan,
      ...this.state.fotbolltransfersSuperettan,
      ...this.state.fotbolltransfersRestOfEurope,
      ...this.state.fotbollDirekt,
      ...this.state.hockeySverige,
      ...this.state.matchcentermedia
    ];

    return combinedNews.sort((a, b) => {
      const dateA = new Date(a.pubDate);
      const dateB = new Date(b.pubDate);
      return dateB - dateA;
    });
  }

  cleanUpContent(text) {
    return text
      .replace(/&quot;/g, '"')
      .replace(/&nbsp;/g, ' ')
      .replace(/\.(?!\s|\.)/g, '. ');
  }

  renderNewsListItem(item) {
    return (
      <li key={item.id + item.source} id={item.id} className={[styles.newsItem, styles.fadeIn].join(' ')}>
        {item.source!=='Matchcentermedia' ? typeof window != 'undefined' && window.navigator.userAgent.includes('Android') ? 
              <a onClick={(e) => {
                  e.preventDefault(); // Отменяем стандартное действие перехода по ссылке
                  // Открываем ссылку внутри WebView
                  window.location.href = new URL(item.link).href;
                }} href={new URL(item.link).href} rel='noopener noreferrer' className={styles.link}>
                  {/* {
                    item.image ? <div className={styles.newsImage}><img src={item.image} alt='' /></div> : "" 
                  } */}
                  <div className={styles.content}>
                    <h5 className={styles.title}>
                      {this.cleanUpContent(item.title)}
                      Android
                    </h5>
                    <div className={styles.bread} dangerouslySetInnerHTML={{ __html: item.description }}>
                    </div>
                    <div className={styles.dateAndLogo}>
                      <span className={styles.publicationDate}>
                        {dateHelper.toStringYYYYMMDDTime(new Date(item.pubDate || item.lastBuildDate))}
                      </span>
                      <img {...rssHelper.feedIcon(item.source)} alt='' />
                    </div>
                  </div>
                </a>
        : <a href={new URL(item.link).href} rel='noopener noreferrer' target="_blank" className={styles.link}>
          {/* {
            item.image ? <div className={styles.newsImage}><img src={item.image} alt='' /></div> : "" 
          } */}
          <div className={styles.content}>
            <h5 className={styles.title}>
              {this.cleanUpContent(item.title)}

            </h5>
            <div className={styles.bread} dangerouslySetInnerHTML={{ __html: item.description }}>
            </div>
            <div className={styles.dateAndLogo}>
              <span className={styles.publicationDate}>
                {dateHelper.toStringYYYYMMDDTime(new Date(item.pubDate || item.lastBuildDate))}
              </span>
              <img {...rssHelper.feedIcon(item.source)} alt='' />
            </div>
          </div>
        </a>
        :
        <Link to={item.linkToNewsPage.slug} className={styles.link}>
          {item.image ? <div className={styles.newsImage}><img src={item.image.file.url} alt='' /></div> : "" }
          <div className={styles.content}>
            <h5 className={styles.title}>
              {this.cleanUpContent(item.title)}
            </h5>
            <div className={styles.bread} dangerouslySetInnerHTML={{ __html: item.description }}>
            </div>
            <div className={styles.dateAndLogo}>
              <span className={styles.publicationDate}>
                {dateHelper.toStringYYYYMMDDTime(new Date(item.pubDate || item.lastBuildDate))}
              </span>
              <img src={item.logoImage.file.url} alt='' />
            </div>
          </div>
        </Link>}
      </li>
    );
  }

  renderFotbollTransfers(newsList) {
    newsList = this.state.league && this.state.league !== 'all' ? this.state[this.state.league] : newsList;
    return (
      <>
        {this.state.loading ? (
          <div className={'loader-container'}>
            <LoaderCSS />
          </div>
        ) : (
            <section className={styles.newsBlock}>
              <div className={styles.newsSelect}>
                <h4>Välj nyheter: </h4>
                <UiSelect options={this.selectOptions} onChange={element => this.setState({ league: element.value })} />
              </div>
              {newsList && (
                <ul className={styles.feedList}>
                  {newsList.slice(0, this.state.visible).map(item => {
                    return this.renderNewsListItem(item);
                  })}
                </ul>
              )}
              <div className={styles.loadMore}>
                {this.state.visible < newsList.length && (
                  <button onClick={this.loadMore} type='button' className={styles.loadMoreButton}>
                    Ladda fler nyheter...
                </button>
                )}
              </div>
            </section>
          )}
      </>
    );
  }
 
  renderNewsItem(newsList) {
    return (
      <>
        {this.state.loading ? (
          <div className={'loader-container'}>
            <LoaderCSS />
          </div>
        ) : (
            <section className={styles.newsBlock}>
              <ul className={styles.feedList}>
                {newsList.slice(0, this.state.visible).map(item => {
                  return this.renderNewsListItem(item);
                })}
              </ul>
              <div className={styles.loadMore}>
                {this.state.visible < newsList.length && (
                  <button onClick={this.loadMore} type='button' className={styles.loadMoreButton}>
                    Ladda fler nyheter...
                </button>
                )}
              </div>
            </section>
          )}
      </>
    );
  }

  render() {
    switch (this.props.newsSource) {
      case 'Alla nyheter':
        const combinedNews = this.combineAndSortFeeds();
        return this.renderNewsItem(combinedNews);
      case 'Fotbollskanalen':
        return this.renderNewsItem(this.state.fotbollskanalenFeed);
      case 'Fotbolldirekt':
        return this.renderNewsItem(this.state.fotbollDirekt);
      case 'Fotbolltransfers':
        return this.renderFotbollTransfers([
          ...this.state.fotbolltransfersAllsvenskan,
          ...this.state.fotbolltransfersSuperettan,
          ...this.state.fotbolltransfersRestOfEurope
        ]);
      case 'Hockey Sverige':
        return this.renderNewsItem(this.state.hockeySverige);
      case 'Matchcentermedia':
        return this.renderNewsItem(this.state.matchcentermedia);
      default:
        return null;
    }
  }
}
export default NewsBlock;

export const fragmentNewsBlock = graphql`
  fragment NewsBlock on ContentfulNewsBlock {
    id
    newsSource
    mcmNewsList {
      mcmNewsList{
        title
        image {
          file {
            url
          }
        }
        description
        text {
          childMarkdownRemark {
            html
          }
        }
        pubDate
        logoImage {
          file {
            url
          }
        }
        linkToNewsPage {
          slug
        }
      }
    }
  }
`;
