import React, { Component } from 'react';
import get from 'lodash/get';
import { graphql } from 'gatsby';
import styles from './tournament-standings-block.module.less';
import SelectDropdown from 'components/block-elements/select-dropdown/select-dropdown';
import UiBkgImage from 'components/block-elements/ui-bkg-image/ui-bkg-image';
import GroupService from 'services/groups-service';

import TournamentStandingsPageWrapper from 'components/block-elements/tournament-standings-page-wrapper/tournament-standings-page-wrapper';

export default class TournamentStandingsBlock extends Component {
  constructor(props) {
    super(props);
    this.tournaments = get(props, 'sport.tournaments');
    this.tournamentImageUrl = get(props, 'sport.image.file.url',"")
    // this.selectOptions = this.formatToSelectOptions(this.tournaments.filter(tournament => tournament.inActivate !== true));
    this.sportId = get(props, 'sport.sportId');

    this.state = {
      selectLeague: null,
      tournaments: []
    };
  }

  async componentDidMount() {
    await GroupService.GetGroupsListForTable(this.sportId)
      .then(res => {
        const data = res.data.map(item => ({
          key: item.id,
          value: item.id,
          text: item.name + ` (${item.year})`,
          label: item.name + ` (${item.year})`,
          tournamentId: item.tournamentId
        }));

        this.setState({ tournaments: data, selectLeague: data instanceof Array && data.length > 0 ? data[0] : null});
      }).catch(err => {
      })
  }

  getSelectedBtnHtml(props){
    return this.state.selectLeague instanceof Object ?
    <><span className={styles.label}>{props.label}</span><span className={styles.selectedOuter}><span className={styles.selectedInner}></span></span></> : ""
  }

  render() {
    return (
      <section className={styles.tournamentStandingsWrapper}>
        {this.state.tournaments?.length > 0 && <div className={['block__max-width', styles.standings].join(' ')}>
          {
            this.tournamentImageUrl ?
            <UiBkgImage
              className={[styles.image__imageWrapper].join(" ")}
              breakPointSmallDevice={1023}
              imageUrlForSmallDevice={this.tournamentImageUrl}
              imageUrlForLargeDevice={this.tournamentImageUrl}
              smallDeviceImageMaxWidth={1540}
              largeDeviceImageMaxWidth={2880}
              ><div className={styles.selectDropDownWrapper}>
              <SelectDropdown selectedListItem={this.state.selectLeague} 
                              list={this.state.tournaments} 
                              onChange={(e,li) => this.setState({ selectLeague : li })}
                              selectBtnHtml={(props) => this.getSelectedBtnHtml(props)} />
          </div></UiBkgImage> : <div className={styles.image__imageWrapper}><div className={styles.selectDropDownWrapper}>
              <SelectDropdown selectedListItem={this.state.selectLeague} 
                              list={this.state.tournaments} 
                              onChange={(e,li) => this.setState({ selectLeague : li })}
                              selectBtnHtml={(props) => this.getSelectedBtnHtml(props)} />
              </div></div>
        }
            
          
          <TournamentStandingsPageWrapper
            key={this.state.selectLeague.value}
            sportId={this.sportId}
            tournamentId={this.state.selectLeague.tournamentId}
            groupId={this.state.selectLeague.value}
            tournamentName = {this.state.selectLeague.text ? this.state.selectLeague.text : this.state.tournaments[0].text}
            noPadding
            fullTablePage={true}
          />
        </div>}
      </section>
    );
  }
}

export const fragmentTournamentStandingsBlock = graphql`
  fragment TournamentStandingsBlock on ContentfulTournamentStandingsBlock {
    id
    sport {
      sportId
      tournaments {
        tournamentName
        tournamentId
        inActivate
      }
      image{
        file{
          url
        }
      }
    }
  }
`;
