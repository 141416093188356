import React, { Component } from 'react';
import { graphql, Link } from 'gatsby';
import get from 'lodash/get';
import styles from './sub-nav.module.less';

class SubNav extends Component {
  constructor(props) {
    super(props);
    this.subNavItems = get(props, 'subNavItems');
    this.iconsOnly = props.mobileBehaviour && props.mobileBehaviour === 'Icons only';
  }

  render() {
    return (
      this.subNavItems instanceof Array &&
      this.subNavItems.length > 0 && (
        <div className={['sub-nav', styles.subNav, this.iconsOnly ? styles.iconsOnly : styles.titlesOnly].join(' ')}>
          <ul className={styles.list}>
            {this.subNavItems.map(item => {
              return (
                <li key={item.id} className={[styles.listItem].join(' ')}>
                  <Link
                    to={item.link.slug}
                    className={[styles.link, 'waves-effect', this.iconsOnly ? styles.iconsOnly : styles.titlesOnly].join(' ')}
                    activeClassName={styles.active}>
                    {item && item.icon && item.icon.file && item.icon.file.url && (
                      <img
                        src={`${item.icon.file.url}?q=10`}
                        alt='Sub Nav Icon'
                        className={[styles.image, this.iconsOnly ? styles.iconsOnly : styles.titlesOnly].join(' ')}
                      />
                    )}
                    <span className={[styles.title, this.iconsOnly ? styles.iconsOnly : styles.titlesOnly].join(' ')}>
                      {item.title}
                    </span>
                  </Link>
                </li>
              );
            })}
          </ul>
        </div>
      )
    );
  }
}

export default SubNav;

export const fragmentSubNav = graphql`
  fragment SubNav on ContentfulSubNav {
    ... on ContentfulSubNav {
      id
      subNavItems {
        id
        icon {
          file {
            url
          }
        }
        link {
          slug
        }
        title
      }
      mobileBehaviour
    }
  }
`;
