import React, { Component } from 'react';
import { graphql } from 'gatsby';
import get from 'lodash/get';
import styles from './mcm-news-block.module.less';
import dateHelper from '../../helpers/date-helper';

class McmNewsBlock extends Component {
  constructor(props) {
    super(props);
    this.title = get(props, 'title');
    this.text = get(props, 'text.childMarkdownRemark.html');
    this.imageUrl = get(props, 'image.file.url');
    this.logoImageUrl = get(props, 'image.logoImageUrl.url');
    this.pubDate = get(props, 'pubDate', '');    
  }

  render() {
    return (
      <section className={styles.mcmNewsBlock}>
          {this.imageUrl ? <div className={styles.mcmNewsImage}><img src={this.imageUrl} alt='' /></div> : "" }
          <div className={styles.content}>
            <h2 className={styles.title}>
              {this.title}
            </h2>
            <div className={styles.dateWrapper}>
              <span className={styles.publicationDate}>
                {dateHelper.toStringYYYYMMDDTime(new Date(this.pubDate))}
              </span>
            </div>
            <div className={styles.text} dangerouslySetInnerHTML={{ __html: this.text }}>
            </div>
          </div>
            </section>
    )
  }
}
export default McmNewsBlock;

export const fragmentMcmNewsBlock = graphql`
  fragment McmNewsBlock on ContentfulMcmNewsBlock {
    id
    title
    image {
      file {
        url
      }
    }
    description
    text {
      childMarkdownRemark {
        html
      }
    }
    pubDate
    logoImage {
      file {
        url
      }
    }
    linkToNewsPage {
      slug
    }
  }
`;
