import React, { Component } from 'react'
import './ui-select.less'
import UiBtn from 'components/block-elements/ui-btn/ui-btn';
import PropTypes from 'prop-types'

export default class UiSelect extends Component {
    static propTypes = {
        options: PropTypes.arrayOf(
            PropTypes.shape({
                key: PropTypes.oneOfType([
                    PropTypes.string,
                    PropTypes.number
                ]),
                text: PropTypes.string,
                value: PropTypes.oneOfType([
                    PropTypes.string,
                    PropTypes.number,
                    PropTypes.object
                ]),
            })
        ).isRequired,
        onChange: PropTypes.func,
        wrapperClassName: PropTypes.string,
        buttonClassName: PropTypes.string,
        icon: PropTypes.shape({
            icon: PropTypes.string,
            align: PropTypes.string
        }),
        includeFocusIndicator: PropTypes.bool,
        isDisabled: PropTypes.bool
    };

    static defaultProps = {
        options: undefined,
        onChange: () => {},
        wrapperClassName: 'ui-select-wrapper',
        buttonClassName: 'ui-btn ui-btn--full-width ui-btn--text-left ui-btn--icon-right-absolute ui-btn--medium ui-btn--border-only',
        icon: { icon: 'icon-keyboard_arrow_left', align: 'right' },
        includeFocusIndicator: true,
        isDisabled: false
    };

    state = {
        activeOption: null,
    };

    constructor(props) {
        super(props);

        this.ref_select = React.createRef();
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        if (!prevState.activeOption && nextProps.options instanceof Array && nextProps.length){
            return nextProps.defaultOption ? {activeOption: nextProps.defaultOption} : {activeOption: nextProps.options[0]};
        }
        return null;
    }

    componentDidUpdate() {
        this.refreshSelectIndex();
    }

    componentDidMount() {
        this.refreshSelectIndex();
    }

    getBtnLabel() {
        const { options, defaultOption } = this.props;
        if (this.state.activeOption) {
            return this.state.activeOption.text
        } else if (defaultOption) {
            return defaultOption.text
        } else if (options instanceof Array && options.length) {
            return options[0].text
        }
        return ''
    }

    onChangeOption(e) {
        e.persist();
        if (e && e.target && e.target.selectedIndex > -1) {
            const activeOption = this.props.options[e.target.selectedIndex];
            this.setState({ activeOption }, () => this.props.onChange(activeOption));
        }
    }

    refreshSelectIndex() {
        const option = this.state.activeOption ? this.state.activeOption : this.props.defaultOption;
        if(!option) {
            return;
        }
        
        const optionIndex = this.props.options.findIndex(opt => option.key === opt.key);
        this.ref_select.current.selectedIndex = optionIndex;
    }

    renderOptions() {
        const { options } = this.props;
        if (options instanceof Array && options.length) {
            return options.map(attr => 
                <option
                    key={attr.key}
                    value={attr.value ? JSON.stringify(attr.value) : ''}>
                    {attr.text}
                </option>
            )
        }
    }

    render() {
        const option = this.state.activeOption ? this.state.activeOption : this.props.defaultOption;
        return (
            <div className={this.props.wrapperClassName}>
                <UiBtn
                    className={this.props.buttonClassName}
                    iconRight={this.props.icon}
                    label={this.getBtnLabel()}
                    tabIndex={-1}
                    isDisabled={this.props.isDisabled}>
                    <select ref={this.ref_select} value={option ? JSON.stringify(option.value) : ''}
                        className={'ui-select'}
                        disabled={this.props.isDisabled}
                        onChange={e => this.onChangeOption(e)}>
                        {this.renderOptions()}
                    </select>
                    {this.props.includeFocusIndicator && <div className={'ui-select__focus-indicator'} />}
                </UiBtn>
            </div>
        )
    }
}